import React, { createContext, useContext, useState } from 'react';

const CategoryContext = createContext();

export const CategoryProvider = ({ children }) => {

    const [selectedRoom, setSelectedRoom] = useState(null);

    //Gestion del contenido de los Alert
    const [message, setMessage] = useState('Esto un mensaje de prueba');
    const [alertType, setAlertType] = useState('success');


    //Gestion del Alert del Header
    const [showAlertHeader, setShowAlertHeader] = useState(false);
    const openAlertHeader = () => {
        setShowAlertHeader(true);
    };
    const closeAlertHeader = () => {
        setShowAlertHeader(false);
    };

    //Gestion del modal Nueva Categoria

    const [showNewRoomModal, setShowNewRoomModal] = useState(false);
    const openNewRoomModal = () => {
        setShowNewRoomModal(true);
    };
    const closeNewRoomModal = () => {
        setShowNewRoomModal(false);
    };

    const [showUpdateRoomModal, setShowUpdateRoomModal] = useState(false);
    const openUpdateRoomModal = (room) => {
        setSelectedRoom(room);
        setShowUpdateRoomModal(true);
    };
    const closeUpdateRoomModal = () => {
        setShowUpdateRoomModal(false);
    };
   

    const [apiSuccess, setApiSuccessSuccess] = useState(false);
    const changeApiSuccess = () => {
        setApiSuccessSuccess(!apiSuccess);
    };
    return (
        <CategoryContext.Provider value={{
            apiSuccess,
            changeApiSuccess,
            alertType,
            setAlertType,
            setMessage,
            message,
            openAlertHeader,
            closeAlertHeader,
            showAlertHeader,
            selectedRoom,
            setSelectedRoom,
            showNewRoomModal,
            openNewRoomModal,
            closeNewRoomModal,
            showUpdateRoomModal,
            openUpdateRoomModal,
            closeUpdateRoomModal,
        }}>
            {children}
        </CategoryContext.Provider>
    );
};

export const useCategory = () => {
    return useContext(CategoryContext);
};
