    import React, { useContext, useRef, useEffect, useState } from 'react';
    import { Link } from 'react-router-dom';
    import { getWithAuth } from '../../api/api';
    import { API_URLS } from '../../api/apiConfig';
    import { useProperty } from '../../property/propertyContext';
    import AutoDismissAlert from '../../components/autoDismiss';
    import ModalNewCategory from '../components/modalNewCategory';
    import ModalUpdateCategory from '../components/modalUpdateCategory';


    const PropertyPage = () => {

        const propertyContext = useProperty()

        const [categoriesList, setListCategories] = useState([]);
        const [isLoading, setIsLoading] = useState(false);
        
        useEffect(() => {
            const fetchData = async () => {
                setIsLoading(true);
                try {
                    console.log("Get Catagories")
                    const data = await getWithAuth(API_URLS.GET_CATEGORIES);
                    setListCategories(data.data);
                    console.log(categoriesList)
                } catch (error) {
                    propertyContext.setMessage("Ha ocurrido un error conectando con el servidor")
                    propertyContext.setAlertType("danger")
                    propertyContext.openAlertHeader()
                    console.error('Error getting cards:', error);
                } finally {
                    setIsLoading(false);
                }
            };

            fetchData();
        }, []); //se indica este parametro para poder actualizar la api desde otro sitio

        return (
            <>
                <div className="d-flex flex-column flex-column-fluid">
                    <div id="kt_app_toolbar" className="app-toolbar">
                        <div id="kt_app_toolbar_container" className="app-container container-fluid d-flex flex-stack">
                            <div className="page-title d-flex flex-column justify-content-center me-3 mb-6 mb-lg-0">
                                <h1
                                    className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center me-3 mb-3 my-0">
                                    Categorías de la propiedad</h1>
                            </div>
                        </div>
                    </div>
                    <div id="kt_app_content" className="app-content flex-column-fluid">
                        <div id="kt_app_content_container" className="app-container container-fluid">
                        {propertyContext.showAlertHeader && (
                            <div className="">
                                <AutoDismissAlert message={propertyContext.message} alertType={propertyContext.alertType} onClose={propertyContext.closeAlertHeader} />
                            </div>
                        )}
                        {isLoading ? (
                            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                                <div className="spinner-grow text-color-amarillo-livensa" style={{ width: "5rem", height: "5em" }} role="status">
                                </div>
                            </div>
                        ) : (
                            <>
                            <div className="card">
                                <div className="card-header border-0 pt-6">
                                    <div className="card-title">
                                        <div className="d-flex align-items-center position-relative my-1">
                                            
                                        </div>
                                    </div>
                                    <div className="card-toolbar">
                                        <div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
                                            <a  onClick={() => propertyContext.openNewCategoryModal()} className="btn btn-sm btn-primary">
                                                <i className="icon-in-button-color ki-outline ki-plus"></i>
                                                Nueva categoría</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body py-4">
                                    <div className="row g-6 mb-6 g-xl-9 mb-xl-9">
                                    {categoriesList.map((category, index) => (
                                        <CategoriesActions category={category} key={index}/>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            </>
                        )}
                        </div>
                    </div>
                    {propertyContext.showNewCategoryModal && (
                        <ModalNewCategory
                            onClose={() => propertyContext.closeNewCategoryModal()}
                        />
                    )}
                    {propertyContext.showUpdateCategoryModal && (
                        <ModalUpdateCategory
                            category={propertyContext.selectedCategory}
                            onClose={() => propertyContext.closeUpdateCategoryModal()}
                        />
                    )}
                </div>
            </>
        );

    };

    export default PropertyPage;


    const CategoriesActions = ({category}) => {
        const [showMenu, setShowMenu] = useState(false);
        const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
        const menuRef = useRef(null);
        const anchorRef = useRef(null);
        const propertyContext = useProperty()

        const toggleDropdown = () => {
            setShowMenu(!showMenu);
        };
        useEffect(() => {
            const handleClickOutside = (event) => {
                if (menuRef.current && !menuRef.current.contains(event.target)) {
                    setShowMenu(false);
                }
            };

            if (showMenu) {
                document.addEventListener('mousedown', handleClickOutside);
            } else {
                document.removeEventListener('mousedown', handleClickOutside);
            }

            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [showMenu]);

        useEffect(() => {
            if (showMenu) {
                // Obtenemos las coordenadas del elemento anchor
                const anchorRect = anchorRef.current.getBoundingClientRect();
                const elementWidth = anchorRef.current.offsetWidth;
                // Calculamos la posición del menú
                const screenWidth = window.innerWidth;
                const menuX = anchorRect.left - screenWidth + elementWidth;
                const menuY = anchorRect.top + anchorRect.height + 10;
                setMenuPosition({ x: menuX, y: menuY });
            }
        }, [showMenu]);

        return (
            <>                
                    <div className="col-md-6 col-xxl-3">
                        <div className="card ">
                            <div className="card-header pt-0 align-items-center border-0">
                                <h3 className="fw-bold text-gray-900 m-0"></h3>
                                <button
                                    onClick={toggleDropdown}
                                    ref={anchorRef}
                                    className="btn btn-icon justify-content-end">
                                    <i className="ki-outline text-color-azul-livensa ki-dots-square fs-1"></i>
                                </button>
                                <div
                                    ref={menuRef}
                                    style={{ transform: `translate3d(${menuPosition.x}px, ${menuPosition.y}px, 0)` }}
                                    className={`menu-actions-table menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4 ${showMenu ? 'show' : ''}`}
                                    data-kt-menu="true"
                                    data-popper-placement="bottom-end"
                                >
                                    <div className="menu-item px-3">
                                        <a onClick={() => propertyContext.openUpdateCategoryModal(category)} className="menu-link px-3">
                                            Editar
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body pt-0 d-flex flex-center flex-column py-9 px-5">
                                <span className="fs-4 text-gray-800 fw-bold mb-0">{category.name}</span>
                                <div className="fw-semibold text-gray-500 mb-6">Tipo: {category.cat_type}</div>
                                <div className="d-flex flex-center flex-wrap mb-5">
                                    <div className="border border-dashed rounded min-w-90px py-3 px-4 mx-2 mb-3">
                                        <div className="fs-6 fw-bold text-gray-700">{category.room_count}</div>
                                        <div className="fw-semibold text-gray-500">Habitaciones</div>
                                    </div>
                                    <div className="border border-dashed rounded min-w-90px py-3 px-4 mx-2 mb-3">
                                        <div className="fs-6 fw-bold text-gray-700">{category.rr_code}</div>
                                        <div className="fw-semibold text-gray-500">Código RR</div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
                                    <Link to={`/property/category/${category.id}`} className="btn btn-sm btn-primary">
                                        <i className="icon-in-button-color ki-outline ki-plus"></i>
                                        Ver habitaciones
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                
                
            </>

        )

    }


