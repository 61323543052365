import React from 'react';
import Swal from 'sweetalert2';
import { deleteWithAuth } from '../../api/api';
import { API_URLS } from '../../api/apiConfig';

class ModalDeleteRule extends React.Component {
    static handleDelete = async (rule_id) => {
        const deleteApi = async (rule_id) => { // Definir deleteApi aquí como una función estática interna
            try {
                await deleteWithAuth(API_URLS.DELETE_RULE(rule_id));
            } catch (error) {
                console.error('Error deleting rule:', error);
                throw error;
            }
        };

        const result = await Swal.fire({
            title: '¡Cuidado!',
            text: 'Borrar una regla de limpieza puede implicar cambios actuales en el panorama general de limpiezas',
            icon: 'info',
            confirmButtonText: `
                Borrar regla
            `,
            customClass: {
                confirmButton: 'btn btn-sm btn-primary',
              },
            showCancelButton: false,
        });

        if (result.isConfirmed) {
            try {
                Swal.showLoading();
                await deleteApi(rule_id); // Utilizar la función deleteApi aquí
                window.location.reload();
            } catch (error) {
                console.error('Error deleting rule:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error al eliminar la regla',
                    text: 'Hubo un problema al intentar eliminar la regla de limpieza. Por favor, inténtalo de nuevo más tarde.',
                });
            }
        }
    };

    render() {
        return null;
    }
}

export default ModalDeleteRule;
