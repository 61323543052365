import React, { useState, useEffect } from 'react';
import { API_URLS } from '../../api/apiConfig';
import { getWithAuth } from '../../api/api';

const ConnectionHistoryComponent = () => {
    const [logs, setLogs] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [alertMessage, setShowAlertMessage] = useState("");
    const [showAlert, setIshowAlert] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const data = await getWithAuth(API_URLS.GET_CONNECTION_HISTORY);
                if (data.message === 'success') {
                    setLogs(data.data);
                } else if (data.message === 'error') {
                    setIsLoading(false);
                    setIshowAlert(true);
                    setShowAlertMessage(data.detail);
                }
            } catch (error) {
                setIsLoading(false);
                setIshowAlert(true);
                setShowAlertMessage(error);
                console.error('Error getting categories:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);


    return (
        <>
            {isLoading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '10vh' }}>
                    <div className="spinner-grow text-color-amarillo-livensa" style={{ width: "5rem", height: "5em" }} role="status">
                    </div>
                </div>
            ) : (
                <div className="card ">
                <div className="card-header card-header-stretch">
                    <div className="card-title">
                        <h3>Histórico de ejecuciones de conexión con PEX
                        </h3>
                    </div>
                </div>

                <div className="card-body p-0">
                    <div className="table-responsive">
                        <table className="table align-middle table-row-bordered table-row-solid gy-4 gs-9" id="kt_api_keys_table">
                            <thead className="border-gray-200 fs-5 fw-semibold bg-lighten">
                                <tr>
                                    <th className="">Fecha y hora</th>
                                    <th className="">Estado</th>
                                    <th className="">Mensaje</th>

                                </tr>
                            </thead>

                            <tbody className="fs-6 fw-semibold text-gray-600">
                                
                            {logs.map((log, index) => (

                                <tr key={index}>
                                    <td>
                                        {log.datetime}
                                    </td>
                                    <td>
                                        {log.status === "Success" ? (
                                            <span className="badge badge-light-success fs-7 fw-semibold">{log.status}</span>
                                        ) : (
                                            <span className="badge badge-light-danger fs-7 fw-semibold">{log.status}</span>
                                        )}
                                    </td>

                                    <td className="pe-9">
                                    {log.message}
                                    </td>

                                </tr>
                                ))}


                            </tbody>

                        </table>

                    </div>

                </div>

            </div>
            )}
        </>
    );
};

export default ConnectionHistoryComponent;
