import React, { useContext, useEffect, useState, useRef, useCallback } from 'react';
import { useInventory } from '../../inventory/inventoryContext';
import CalendarComponent from '../components/calendar';
import { API_URLS } from '../../api/apiConfig';
import Form from "react-bootstrap/Form";
import { getWithAuth } from '../../api/api';
import CalendarInventoryGapComponent from '../components/calendar_inventory';
import ModalGapInfo from '../components/modalGapInfo';
import ConnectionHistoryComponent from '../components/connection_history';


const InventoryPage = () => {
    const inventoryContext = useInventory();

    return (
        <>
            <div className="d-flex flex-column flex-column-fluid">
                <div id="kt_app_toolbar" className="app-toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-fluid d-flex flex-stack">
                        <div className="page-title d-flex flex-column justify-content-center me-3 mb-6 mb-lg-0">
                            <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center me-3 mb-3 my-0">
                                Inventario
                            </h1>
                        </div>
                    </div>
                </div>

                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-fluid">
                    
                    <div className="row g-5 g-xl-10 mb-xl-10">
                            <div className="col-lg-12 col-xl-12 col-xxl-12 mb-5 mb-xl-0">
                                <TimelineSection/>
                            </div>
                    </div>

                    <div className="page-title d-flex flex-column justify-content-center me-3 mb-6 mb-lg-0">
                            <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center me-3 mb-3 my-0">
                                Calendario
                            </h1>
                    </div>
                    <div className="row g-5 g-xl-10 mb-xl-10">
                            <div className="col-lg-12 col-xl-12 col-xxl-12 mb-5 mb-xl-0">
                                <CalendarSection/>
                            </div>

                    </div>

                    <div className="page-title d-flex flex-column justify-content-center me-3 mb-6 mb-lg-0">
                            <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center me-3 mb-3 my-0">
                                Inventory GAP
                            </h1>
                    </div>
                    <div className="row g-5 g-xl-10 mb-xl-10">
                            <div className="col-lg-12 col-xl-12 col-xxl-12 mb-5 mb-xl-0">
                                <CalendarInventoryGapSection/>
                            </div>

                    </div>

                    <div className="row g-5 g-xl-10 mb-xl-10">
                            <div className="col-lg-12 col-xl-12 col-xxl-12 mb-5 mb-xl-0">
                                <ConnectionHistoryComponent/>
                            </div>

                    </div>

                </div>
    

                </div>
            </div>

            {inventoryContext.showGapInfoModal && 
            <ModalGapInfo
            inventory_gap_id={inventoryContext.selectedGap}
            onClose={() => inventoryContext.closeGapInfoModal()} 
            />
            }

        </>
    );
};

export default InventoryPage;


const CalendarSection = () => {

    const calendarRef = useRef(null)
    const inventoryContext = useInventory();
    const [habitaciones, setHabitaciones] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date(startDate.getTime() + 7 * 24 * 60 * 60 * 1000));

    useEffect(() => {
        const fetchAllRooms = async () => {
            setIsLoading(true);
            try {
                setIsLoading(true)
                console.log("Get cleaning")
                const data = await getWithAuth(API_URLS.GET_ALL_ROOMS);
                console.log(data);
                
                const habitacionesData = data.data.map(room => ({
                    id: room.id,
                    title: `${room.number} ${room.name}`
                }));
                console.log(habitacionesData)
                setHabitaciones(habitacionesData);
            } catch (error) {
                inventoryContext.setMessage("Ha ocurrido un error conectando con el servidor")
                inventoryContext.setAlertType("danger")
                inventoryContext.openAlertHeader()
                console.error('Error getting cards:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchAllRooms();
    }, []);


    return (
        <>  
            <div className="card card-calendar-height">
                <div className="card-header border-0 pt-5">

                <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold text-gray-900">
                <div className="d-flex flex-column mb-5 fv-row fv-plugins-icon-container">
                    <div className="d-flex"> {/* Div para colocar los FormControl en una fila */}
                        <Form.Control 
                            className="m-3 d-flex align-items-center" 
                            placeholder='Fecha de inicio' 
                            name="start" 
                            value={startDate.toISOString().split('T')[0]} // Convertir startDate a 'YYYY-MM-DD'
                            onChange={(e) => setStartDate(new Date(e.target.value))} // Actualizar el estado startDate cuando cambie la entrada
                            type="date" 
                        />
                        <Form.Control 
                            className="m-3 d-flex align-items-center" 
                            placeholder='Fecha de fin' 
                            name="end" 
                            value={endDate.toISOString().split('T')[0]} // Convertir startDate a 'YYYY-MM-DD'
                            onChange={(e) => setEndDate(new Date(e.target.value))} // Actualizar el estado startDate cuando cambie la entrada
                            type="date" 
                        />
   
                    </div>
                </div>
            </span>
        </h3>

                    <div className="card-toolbar">
                    <a className="m-3 btn btn-sm btn-primary" onClick={() => inventoryContext.openNewBookingModal()}>Nuevo Bloqueo</a>
                    </div>
                </div>
                    <div className="card-body border-0 pt-5">
                    <CalendarComponent 
                        startDate={startDate}
                        endDate={endDate}
                    />
                    </div>
                  
            </div>
             

        </>
        );
}

const CalendarInventoryGapSection = () => {

    const calendarRef = useRef(null)
    const inventoryContext = useInventory();
    const [habitaciones, setHabitaciones] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date(startDate.getTime() + 7 * 24 * 60 * 60 * 1000));

    useEffect(() => {
        const fetchAllRooms = async () => {
            setIsLoading(true);
            try {
                setIsLoading(true)
                console.log("Get cleaning")
                const data = await getWithAuth(API_URLS.GET_ALL_ROOMS);
                console.log(data);
                
                const habitacionesData = data.data.map(room => ({
                    id: room.id,
                    title: `${room.number} ${room.name}`
                }));
                console.log(habitacionesData)
                setHabitaciones(habitacionesData);
            } catch (error) {
                inventoryContext.setMessage("Ha ocurrido un error conectando con el servidor")
                inventoryContext.setAlertType("danger")
                inventoryContext.openAlertHeader()
                console.error('Error getting cards:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchAllRooms();
    }, []);


    return (
        <>  
            <div className="card card-calendar-height">
                <div className="card-header border-0 pt-5">

                <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold text-gray-900">
                <div className="d-flex flex-column mb-5 fv-row fv-plugins-icon-container">
                    <div className="d-flex"> {/* Div para colocar los FormControl en una fila */}
                        <Form.Control 
                            className="m-3 d-flex align-items-center" 
                            placeholder='Fecha de inicio' 
                            name="start" 
                            value={startDate.toISOString().split('T')[0]} // Convertir startDate a 'YYYY-MM-DD'
                            onChange={(e) => setStartDate(new Date(e.target.value))} // Actualizar el estado startDate cuando cambie la entrada
                            type="date" 
                        />
                        <Form.Control 
                            className="m-3 d-flex align-items-center" 
                            placeholder='Fecha de fin' 
                            name="end" 
                            value={endDate.toISOString().split('T')[0]} // Convertir startDate a 'YYYY-MM-DD'
                            onChange={(e) => setEndDate(new Date(e.target.value))} // Actualizar el estado startDate cuando cambie la entrada
                            type="date" 
                        />
   
                    </div>
                </div>
            </span>
        </h3>

                    <div className="card-toolbar">
                    <a className="m-3 btn btn-sm btn-primary" onClick={() => inventoryContext.openNewBookingModal()}>Nuevo Bloqueo</a>
                    </div>
                </div>
                    <div className="card-body border-0 pt-5">
                    <CalendarInventoryGapComponent 
                        startDate={startDate}
                        endDate={endDate}
                    />
                    </div>
                  
            </div>
             

        </>
        );
}


const TimelineSection = () => {
    const [isLoading, setIsLoading] = useState(false);
    const today = new Date();
    const dayList = [];
    let lastDay = new Date();
    const inventoryContext = useInventory();
    const [alertsList, setAlertsCleanings] = useState(null);
    const [dataLoaded, setDataLoaded] = useState(false);

    for (let i = 0; i < 10; i++) {
        const date = new Date(today);
        date.setDate(today.getDate() + i);
        dayList.push(date.toDateString());
        lastDay = date;
    }

    function fetchDataWithDates() {
        let dateTimeline = inventoryContext.dateTimelineActive;
        console.log(dateTimeline);
        dateTimeline = new Date(dateTimeline + 'Z');
        const date_timeline_formatted = dateTimeline.toISOString().split('T')[0];
        console.log(date_timeline_formatted);
        return getWithAuth(API_URLS.GET_INVENTORY_ALERTS(date_timeline_formatted));
    }

    async function fetchData() {
        setIsLoading(true);
        try {
            const alerts = await fetchDataWithDates();
            setAlertsCleanings(alerts.data || []); // Set an empty array if alerts.data is null or undefined
        } catch (error) {
            console.error('Error getting cleanings:', error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        const fetchDataAll = async () => {
            await fetchData();
            setDataLoaded(true);
        };
        fetchDataAll();
    }, [inventoryContext.dateTimelineActive]);

    const activeClass = (day) => {
        return day === inventoryContext.dateTimelineActive ? 'active' : '';
    };

    const handleChangeStatus = (cleaning_id) => {
        // ModalChangeStatusCleaning.handleChangeStatus(cleaning_id);
    };

    const getAlertMessage = (alertType) => {
        console.log(alertType);
        if (alertType === "OverBooking") {
            return "Overbooking RR y PEX";
        } else if (alertType === "NotAvailableMant") {
            return "Hay una reserva en RR pero mantenimiento en PEX";
        } else if (alertType === "BookingWithoutAvai") {
            return "Hay una reserva sin disponibilidad";
        } else {
            return "Creada por algoritmo";
        }
    };

    return (
        <>  
            <div className="card h-md-100">
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold text-gray-900">Alertas de inventario</span>
                        <span className="text-muted mt-1 fw-semibold fs-7"></span>
                    </h3>
                </div>
                <div className="card-body pt-7 px-0">
                    {isLoading ? (
                        <div className="d-flex justify-content-center align-items-center" style={{ height: '10vh' }}>
                            <div className="spinner-grow text-color-amarillo-livensa" style={{ width: "5rem", height: "5rem" }} role="status">
                            </div>
                        </div>
                    ) : (
                        <>
                            <ul className="nav nav-stretch nav-pills nav-pills-custom nav-pills-active-custom d-flex justify-content-between mb-8 px-5">
                                {dayList.map((day, index) => (
                                    <li key={index} className="nav-item p-0 ms-0">
                                        <a onClick={() => inventoryContext.setDateTimelineActive(day)} className={`nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger ${activeClass(day)}`} data-bs-toggle="tab">
                                            <span className="fs-7 fw-semibold">{day.substring(0, 3)}</span>
                                            <span className="fs-6 fw-bold">{day.substring(8, 10)}</span>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                            <div className="tab-content mb-2 px-9">
                                <div className="tab-pane fade show active fixed-height-scroll" id="timeline_content">
                                    {alertsList && alertsList.length > 0 ? (
                                        alertsList.map((alert, index) => {
                                            let bgColorClass = '';

                                            switch (alert.alert_type) {
                                                case 'BLOCK_PEX_BOOKING_RR':
                                                    bgColorClass = 'bg-alert-inventory-marron';
                                                    break;
                                                case 'AVAIABILITY':
                                                    bgColorClass = 'bg-alert-inventory-morado';
                                                    break;
                                                case 'BOOKING_PEX_RR':
                                                    bgColorClass = 'bg-alert-inventory-rojo';
                                                    break;
                                                default:
                                                    break;
                                            }

                                            return (
                                                <div key={index} className="d-flex align-items-center mb-6">
                                                    <span data-kt-element="bullet" className={`bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 ${bgColorClass}`}>
                                                    </span>
                                                    <div className="flex-grow-1 me-5">
                                                        <div className="text-gray-800 fw-semibold fs-2">{alert.room_details.number} {alert.room_details.name}
                                                            <span className="text-gray-500 fw-semibold fs-7 m-4">
                                                                {alert.status}
                                                            </span>
                                                        </div>
                                                        <div className="text-gray-500 fw-semibold fs-7">Reserva: {alert.booking_details.external_id}</div>
                                                        <div className="text-gray-500 fw-semibold fs-7">{getAlertMessage(alert.alert_type)}
                                                        </div>
                                                    </div>
                                                    {alert.status === 'Sin resolver' ? (
                                                        <a onClick={() => handleChangeStatus(alert.id)} className="btn btn-sm btn-primary">Solucionar</a>
                                                    ) : (
                                                        <a onClick={() => handleChangeStatus(alert.id)} className="btn btn-sm btn-secondary">Solucionado</a>
                                                    )}
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <div className="d-flex justify-content-center align-items-center" style={{ height: '10vh' }}>
                                            <p>No hay alertas para esta fecha.</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};
