import React from 'react';
import { useState } from 'react';
import Form from "react-bootstrap/Form";
import { Button, Modal } from 'react-bootstrap';
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import { putWithAuth } from '../../api/api';
import { API_URLS } from '../../api/apiConfig';

const ModalUpdateRule = ({onClose, rule}) => {

    const [tags, setTags] = useState(rule.cleaning_days);
    const [tagsTowle, setTagsTowle] = useState(rule.towel_days);
    const [alertMessage, setShowAlertMessage] = useState("");
    const [updateRule, setRule] = useState(rule);

    const handleTagsChange = (tags) => {
        setTags(tags);
        setRule((prevRule) => ({
            ...prevRule,
            cleaning_days: tags, 
        }));
        console.log(updateRule)
    };

    const handleTagsTowlesChange = (tagsTowle) => {
        setTagsTowle(tagsTowle);
        setRule((prevRule) => ({
            ...prevRule,
            towel_days: tagsTowle, 
        }));
        console.log(updateRule)
    };

    const [showAlert, setIshowAlert] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleCleaningChange = (e) => {
        const value = e.target.value === 'true';
    
        setRule((prevRule) => ({
            ...prevRule,
            check_out_cleaning: value,
        }));
        console.log(updateRule)
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;

        setRule((prevRule) => {
            const updateRule = {
                ...prevRule,
                cleaning_days: tags,
                towel_days:tagsTowle,
                [name]: type === 'checkbox' ? !prevRule[name] : value,
            };
            return updateRule;
        });

        console.log(updateRule)
    };

    const saveRule = async (e) => {

        const formData = new FormData();
        formData.append('data', JSON.stringify(updateRule));
        try {
            setIsLoading(true)
            const response = await putWithAuth(
                API_URLS.EDIT_RULE(rule.id),
                updateRule
            );
            console.log(response)
            if (response.message === 'success') {
                setIsLoading(false)
                window.location.reload();
            } else if (response.message === 'error') {
                setIsLoading(false)
                setIshowAlert(true);
                setShowAlertMessage(response.detail)
            } else {
                setIsLoading(false)
                setIshowAlert(true);
                setShowAlertMessage("Ha ocurrido un error")
            }
        } catch (error) {
            console.error('Error creating user admin:', error);
        }

    };

    const handleSave = async (e) => {
        e.preventDefault();

        try {
            saveRule();
        } catch (error) {
            console.error('Error creating user admin:', error);
        }
    };

    return (
        <>

            <Modal show={true} onHide={onClose} size="xl" centered>
            <Modal.Header closeButton>
                <Modal.Title>Editar Regla</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="form fv-plugins-bootstrap5 fv-plugins-framework" action="#" data-select2-id="select2-data-kt_modal_create_api_key_form">
                    <div className="modal-body py-10 px-lg-17" data-select2-id="select2-data-108-9vb6">
                        <div className="scroll-y me-n7 pe-7" id="kt_modal_create_api_key_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_create_api_key_header" data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll" data-kt-scroll-offset="300px" data-select2-id="select2-data-kt_modal_create_api_key_scroll">
                            <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-10 p-6">
                                <i className="ki-outline ki-information fs-2tx text-warning me-4"></i>
                                <div className="d-flex flex-stack flex-grow-1 ">
                                    <div className=" fw-semibold">
                                        <h4 className="text-gray-900 fw-bold">Atención!</h4>
                                        <div className="fs-6 text-gray-700 ">Agregar o editar una regla puede cambiar el panorama actual de limpiezas</div>
                                    </div>
                                </div>
                            </div>
                            {showAlert && (
                            <div className="alert alert-danger d-flex align-items-center p-5">
                                <i className="fa-solid fa-triangle-exclamation fs-1 text-danger me-4"><span className="path1"></span><span className="path2"></span></i>
                                <div className="d-flex flex-column">
                                    <span className="mb-1 text-danger">{alertMessage}</span>
                                </div>
                            </div>
                            )}

                            <div className="row g-9 mb-8">
                                <div className="col-md-12 fv-row fv-plugins-icon-container">
                                    <label className="required fs-6 fw-semibold mb-2">Número de noches</label>
                                    <input 
                                    type="number" 
                                    className="form-control form-control-solid" 
                                    placeholder="Número de noches" 
                                    onChange={handleInputChange}
                                    defaultValue={rule.number_of_nights}
                                    name="number_of_nights"
                                    />
                                </div>
                            </div>

                            <div className="row g-9 mb-8">
                                <div className="col-md-12 fv-row fv-plugins-icon-container">
                                    <label className="required fs-6 fw-semibold mb-2">Días de limpieza</label>
                                    <TagsInput
                                        className="form-control form-control-solid tag-input"
                                        value={tags}
                                        placeholder='Añade un número de noche'
                                        inputProps={{
                                            className: 'tag-input-input',
                                            placeholder: 'Añade un día'
                                        }}
                                        tagProps={{
                                            className: 'tag-input',
                                            classNameRemove: 'react-tagsinput-remove'
                                        }}
                                        onChange={handleTagsChange} />
                                </div>
                            </div>

                            <div className="row g-9 mb-8">
                                <div className="col-md-12 fv-row fv-plugins-icon-container">
                                    <label className="required fs-6 fw-semibold mb-2">Días de cambio de sábanas/toallas</label>
                                    <TagsInput
                                        className="form-control form-control-solid tag-input"
                                        placeholder='Añade un número de noche'
                                        value={tagsTowle}
                                        inputProps={{
                                            className: 'tag-input-input',
                                            placeholder: 'Añade un día'
                                        }}
                                        tagProps={{
                                            className: 'tag-input',
                                            classNameRemove: 'react-tagsinput-remove'
                                        }}
                                        onChange={handleTagsTowlesChange} />
                                </div>
                            </div>

                            <div className="row g-9 mb-8">
                                <div className="col-md-12 fv-row">
                                    <div className="d-flex flex-stack">
                                        <div className="me-5">
                                            <label className="fs-6 fw-semibold form-label">Ejecutar Regla despues de guardar</label>
                                            <div className="fs-7 fw-semibold text-muted">Se ejcutará esta regla para las reservas futuras que ya están en el sistema</div>
                                        </div>
                                        <label className="form-check form-switch form-check-custom form-check-solid">
                                            <select className="form-select" aria-label="Select example">
                                                <option>Si</option>
                                                <option value="1">No</option>
                                            </select>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="row g-9 mb-8">
                                <div className="col-md-12 fv-row">
                                    <div className="d-flex flex-stack">
                                        <div className="me-5">
                                            <label className="fs-6 fw-semibold form-label">Limpieza al salir</label>
                                            <div className="fs-7 fw-semibold text-muted">Define si la habitación se limpia al salir o no</div>
                                        </div>
                                        <label className="form-check form-switch form-check-custom form-check-solid">
                                            <select 
                                            onChange={handleCleaningChange} 
                                            name="check_out_cleaning" 
                                            defaultValue={rule.check_out_cleaning ? "true" : "false"} // Establecemos el valor del select según el valor de la cadena
                                            className="form-select" 
                                            aria-label="Select example">
                                                <option value={true}>Si</option>
                                                <option value={false}>No</option>
                                            </select>
                                        </label>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="modal-footer flex-center">

                        <button type="reset" id="kt_modal_create_api_key_cancel" className="btn btn-light me-3">
                            Cancelar
                        </button>
                        <button type="submit" onClick={handleSave} className="btn btn-primary" data-kt-indicator={`${isLoading ? 'on' : 'off'}`}>
                            <span className="indicator-label">
                                Editar Regla
                            </span>
                            <span className="indicator-progress">
                                Guardando... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>

                    </div>

                </form>

            </Modal.Body>
        </Modal>

        </>

    );
};

export default ModalUpdateRule;