


import React from 'react';
import { useState } from 'react';
import Form from "react-bootstrap/Form";
import { API_URLS } from '../api/apiConfig';
import { postWithAuth } from '../api/api';

const ModalAllProperties = ({userData}) => {

    const handlePropertyClick = async (property_id) => {
        try {
            const response = await postWithAuth(API_URLS.UPDATE_CURRENT_PROPERTY(property_id));
            if (response.message === 'success') {
                window.location.reload();
            } else {
                console.error('Error al actualizar la propiedad:', response.error);
            }
        } catch (error) {
            console.error('Error al realizar la llamada a la API:', error);
        }
    };

    return (
        <>
            <div className="modal fade" id="modal_all_properties" tabIndex="-1" aria-modal="true" role="dialog" >
                <div className="modal-dialog mw-650px">
                    <div className="modal-content">
                        <div className="modal-header pb-0 border-0 justify-content-end">
                            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                <i className="ki-outline ki-cross fs-1"></i>                </div>

                        </div>
                        <div className="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
                            <div className="text-center mb-13">
                                <h1 className="mb-3">Elije la propiedad</h1>
                                <div className="text-muted fw-semibold fs-5">
                                    Al cambiar de propiedad, cambia el entorno y pasarás a ver toda la info de la propiedad elegida
                                </div>
                            </div>
                            <div className="mb-15">
                                <div className="mh-375px scroll-y me-n7 pe-7">
                                {userData.properties_list.map((property, index) => (
                                    <div key={index} className="d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed">
                                        <div className="d-flex align-items-center">
                                            <i className="fa-solid fa-building text-color-azul-livensa fs-2x"></i>
                                            <div className="ms-6">
                                                <span className="d-flex align-items-center fs-5 fw-bold text-gray-900">
                                                    {property.property_name}
                                                </span>
                                                <div className="fw-semibold text-muted"><a href="" onClick={() => handlePropertyClick(property.property_id)}>Ir a la propiedad</a></div>
                                            </div>
                                        </div>
                                   
                                    </div>
                                ))}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>

    );


};

export default ModalAllProperties;