import React from 'react';
import { useState, useEffect } from 'react';
import Form from "react-bootstrap/Form";
import { Button, Modal } from 'react-bootstrap';
import { getWithAuth, postWithAuth } from '../../api/api';
import { API_URLS } from '../../api/apiConfig';
import { useNavigate } from 'react-router-dom';
import { useSettings } from '../../settings/settingsContext';


const ModalNewMap = ({ onClose}) => {

    const navigate = useNavigate();
    const [showAlert, setIshowAlert] = useState(false);
    const [alertMessage, setShowAlertMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingRooms, setIsLoadingRooms] = useState(false);
    const [habitaciones, setHabitaciones] = useState([]);
    const settingsContext = useSettings()

    const [updateMap, setMap] = useState({
        pex_number: "",
        pex_name: "",
        room_id: "null"
    });

    useEffect(() => {
        const fetchAllRooms = async () => {
            setIsLoadingRooms(true);
            try {
                setIsLoadingRooms(true)
                console.log("Get cleaning")
                const data = await getWithAuth(API_URLS.GET_ALL_ROOMS);
                console.log(data);
                
                const habitacionesData = data.data.map(room => ({
                    id: room.id,
                    title: `${room.number} ${room.name}`
                }));
                console.log(habitacionesData)
                setHabitaciones(habitacionesData);
            } catch (error) {
                setShowAlertMessage("Ha ocurrido un error conectando con el servidor")
                setIshowAlert(true)
                console.error('Error getting cards:', error);
            } finally {
                setIsLoadingRooms(false);
            }
        };
        fetchAllRooms();
    }, []);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;

        setMap((prevMap) => {
            const updateMap = {
                ...prevMap,
                [name]: type === 'checkbox' ? !prevMap[name] : value,
            };
            return updateMap;
        });

        console.log(updateMap)
    };

    const saveMap = async (e) => {

        const formData = new FormData();
        formData.append('data', JSON.stringify(updateMap));
        try {
            setIsLoading(true)
            const response = await postWithAuth(
                API_URLS.NEW_PEX_MAPPING,
                updateMap
            );
            console.log("FormData", formData)
            if (response.message === 'success') {
                setIsLoading(false)
                window.location.reload();
            } else if (response.message === 'error') {
                setIsLoading(false)
                setIshowAlert(true);
                setShowAlertMessage(response.detail)
            } else {
                //setAlertType('danger');
                //setMessage("Error inesperado");
                //setShowAlertHeader(true);
            }
        } catch (error) {
            console.error('Error creating user admin:', error);
        }

    };

    const handleSave = async (e) => {
        e.preventDefault();

        try {
            saveMap();
        } catch (error) {
            console.error('Error creating user admin:', error);
        }
    };


    return (
        <>
            <Modal show={true} onHide={onClose} size="xl" centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Nuevo mapeo de habitación</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <form className="form fv-plugins-bootstrap5 fv-plugins-framework" data-select2-id="select2-data-kt_modal_create_api_key_form">
                <div className="modal-body py-10 px-lg-17" data-select2-id="select2-data-108-9vb6">
                    <div className="scroll-y me-n7 pe-7" id="kt_modal_create_api_key_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_create_api_key_header" data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll" data-kt-scroll-offset="300px" data-select2-id="select2-data-kt_modal_create_api_key_scroll">
                        <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-10 p-6">
                            <i className="ki-outline ki-information fs-2tx text-warning me-4"></i>
                            <div className="d-flex flex-stack flex-grow-1 ">
                                <div className=" fw-semibold">
                                    <h4 className="text-gray-900 fw-bold">Atención!</h4>
                                    <div className="fs-6 text-gray-700 ">Agregar o editar una habitación puede tener consecuencias en tu inventario de disponibilidad</div>
                                </div>
                            </div>
                        </div>
                        {showAlert && (
                            <div className="alert alert-danger d-flex align-items-center p-5">
                                <i className="fa-solid fa-triangle-exclamation fs-1 text-danger me-4"><span className="path1"></span><span className="path2"></span></i>
                                <div className="d-flex flex-column">
                                    <span className="mb-1 text-danger">{alertMessage}</span>
                                </div>
                            </div>
                            )}
                        
                        {isLoadingRooms ? (
                            <div className="d-flex justify-content-center align-items-center" style={{ height: '10vh' }}>
                                <div className="spinner-grow text-color-amarillo-livensa" style={{ width: "5rem", height: "5em" }} role="status">
                                </div>
                            </div>
                        ) : (
                        <>
                        <div className="row g-9 mb-8">
                            <div className="col-md-6 fv-row fv-plugins-icon-container">
                                <label className="required fs-6 fw-semibold mb-2">Número en PEX</label>
                                <input 
                                type="text" 
                                className="form-control form-control-solid" 
                                placeholder="12" 
                                name="pex_number"
                                onChange={handleInputChange}
                                defaultValue={""}
                                />
                            </div>
                            <div className="col-md-6 fv-row fv-plugins-icon-container">
                                <label className="required fs-6 fw-semibold mb-2">Nombre en PEX</label>
                                <input 
                                type="text" 
                                className="form-control form-control-solid" 
                                placeholder="Doble con vistas al mar y baño" 
                                name="pex_name"
                                onChange={handleInputChange}
                                defaultValue={""}
                                />
                            </div>
                        </div>

                        <div className="row g-9 mb-8">
                        <div className="d-flex flex-column mb-5 fv-row fv-plugins-icon-container">
                            <label for="exampleFormControlInput1" className="form-label">Habitación</label>

                            <select className="form-select" onChange={handleInputChange} name="room_id" aria-label="Select example">
                            <option value="">Selecciona una habitación</option>
                            {habitaciones.map(room => (
                                <option key={room.id} value={room.id}>{room.title}</option>
                            ))}
                            </select>
                        </div>
                        
                        </div>
                        </>
                        )}    
                    </div>
                </div>

                <div className="modal-footer flex-center">

                    <button type="reset" id="kt_modal_create_api_key_cancel" className="btn btn-light me-3">
                        Cancelar
                    </button>
                    <button type="submit" onClick={handleSave} className="btn btn-primary" data-kt-indicator={`${isLoading ? 'on' : 'off'}`}>
                        <span className="indicator-label">
                            Crear mapeo
                        </span>
                        <span className="indicator-progress">
                            Guardando ... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>

                </div>

            </form>
            </Modal.Body>
        </Modal>

        </>

    );
};

export default ModalNewMap;