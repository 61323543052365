import React, { createContext, useContext, useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { API_URLS } from '../../api/apiConfig';
import { postWithAuth, getWithAuth } from '../../api/api';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';


export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const cookies = new Cookies();
    const navigate = useNavigate();

    const [authData, setAuthData] = useState({
        isAuthenticated: false,
        session_token: null,
        user_data: null
    });

    const login = async (email, password) => {
        try {
            const response = await postWithAuth(API_URLS.AUTH_LOGIN, { email, password });
            if (response.message == "success"){
                cookies.set('session_token', response.data.session_token, { path: '/' });
                return true;
            } else {
                return false;
            }
            
        } catch (error) {
            console.error('Error al iniciar sesión:', error);
            return false;
        }
    };

    const getSessionUser = async () => {
        try {
            console.log("Call to API User Session")
            const rsp = await getWithAuth(API_URLS.GET_USER_SESSION);
            const user_data = rsp.data;
            const newAuthData = {
                isAuthenticated: true,
                session_token:  cookies.get('session_token'),
                user_data: user_data,
            };
            setAuthData(newAuthData);
            return newAuthData;
        } catch (error) {
            navigate('/login');
            console.error('Error al actualizar usuario:', error);
            return false;
        }
    };


    const logout = () => {
        console.log("Logout")
        cookies.remove('session_token');
        setAuthData({
            isAuthenticated: false,
            session_token: null,
            user_data: null
        });
        navigate('/login');
    };

    return (
        <AuthContext.Provider value={{ authData, setAuthData, login, logout, getSessionUser }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
