import React, { useState, useEffect } from 'react';
import { BryntumScheduler } from '@bryntum/scheduler-react';
import { schedulerConfig } from '../calendar_config/AppConfig';
import { API_URLS } from '../../api/apiConfig';
import { getWithAuth } from '../../api/api';
import { useInventory } from '../../inventory/inventoryContext';

const CalendarComponent = ({ startDate, endDate }) => {
    const [resources, setResources] = useState([]);
    const [events, setEvents] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // Estado para controlar la carga
    const [updatedSchedulerConfig, setUpdatedSchedulerConfig] = useState({ ...schedulerConfig }); // Estado para actualizar la configuración del scheduler
    const inventoryContext = useInventory();


    useEffect(() => {
        fetchData(startDate, endDate);
    }, [startDate, endDate]);

    const fetchData = async (start, end) => {
        try {
            setIsLoading(true);
            const formattedToday = start.toISOString().split('T')[0];
            const formattedNextWeek = end.toISOString().split('T')[0];
            const response = await getWithAuth(API_URLS.GET_INVENTORY(formattedToday, formattedNextWeek));
            if (response.success) {
                setResources(response.resources.rows);
                setEvents(response.events.rows);
                setIsLoading(false);
            } else {
                console.error('Failed to fetch data');
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    // Define el callback para el evento click en un evento del scheduler
    const handleEventClick = ({ eventRecord }) => {
        console.log('Evento clickeado:', eventRecord);
       
    };

    const handleTimeSlotClick = ({ resourceRecord, date }) => {
        console.log(date);
       
    };

    useEffect(() => {
        // Actualizar la configuración del scheduler con las nuevas fechas y el listener
        const config = {
            ...schedulerConfig,
            startDate: startDate,
            endDate: endDate,
            zoomOnMouseWheel: false,
            eventDragCreate: false, // Desactivar la creación de eventos al arrastrar
            eventResize: false, 
            zoomOnTimeAxisDoubleClick: false, // Deshabilitar el zoom en el eje de tiempo
            listeners: {
                eventClick: handleEventClick,
                scheduleClick: handleTimeSlotClick
            }
        };
        setUpdatedSchedulerConfig(config);
    }, [startDate, endDate]);

    return (
        <>
            {isLoading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '10vh' }}>
                    <div className="spinner-grow text-color-amarillo-livensa" style={{ width: "5rem", height: "5em" }} role="status">
                    </div>
                </div>
            ) : (
                <BryntumScheduler
                    {...updatedSchedulerConfig}
                    resources={resources}
                    events={events}
                />
            )}
        </>
    );
};

export default CalendarComponent;
