import React, { createContext, useContext, useState, useEffect } from 'react';
import logo from '../assets/media/livensa/Studios-livensaliving.png';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAuth } from '../base/context/authcontext';
import Cookies from 'universal-cookie';


const LoginPage = () => {

    const { login } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setIshowAlert] = useState(false);
    const [sessionTokenExists, setSessionTokenExists] = useState(false);
    const cookies = new Cookies();

    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const success = await login(email, password);
        if (success) {
            console.log("Login success")
            setIshowAlert(false)
            navigate('/planification');
            setSessionTokenExists(true)
        } else {
            console.log("Login No success")
            setIshowAlert(true)
        }
        setIsLoading(false);
    }
    
    useEffect(() => {
        const sessionToken = cookies.get('session_token');
        if (sessionToken) {
            navigate('/planification');
        }
    }, [sessionTokenExists]);
    

    return (
        <>
                <div className="d-flex flex-column flex-lg-row flex-column-fluid">
                    <div className="d-flex flex-lg-row-fluid w-lg-50 bg-azul-color-azul-livensa">
                        <div className="d-flex flex-column flex-center p-6 p-lg-10 w-100">
                            <a href="" className="mb-0 mb-lg-20">
                                <img alt="Logo" src={logo} className="h-40px h-lg-50px" />
                            </a>
                            <img className="d-none d-lg-block mx-auto w-300px w-lg-75 w-xl-500px mb-10 mb-lg-20" src="/media/misc/auth-screens.png" alt="" />

                            <h1 className="d-none d-lg-block text-white fs-2qx fw-bold text-center mb-7">
                                Rápido, eficiente y productivo
                            </h1>
                            <div className="d-none d-lg-block text-white fs-base text-center">
                                Gestiona de manera <a className="opacity-75-hover text-warning fw-semibold me-1">inteligente </a>las habitaciones
                                <br /> de tus alojamientos con una interfaz

                                <a className="opacity-75-hover text-warning fw-semibold me-1"> rápida</a>
                                que mejora en gran medida tu <br /> <a className="opacity-75-hover text-warning fw-semibold me-1">productividad</a>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10">
                        <div className="d-flex flex-center flex-column flex-lg-row-fluid">
                            <div className="w-lg-500px p-10">
                                <form className="form w-100" onSubmit={handleLogin}>

                                    <div className="text-center mb-11">
                                        <h1 className="text-gray-900 fw-bolder mb-3">
                                            Iniciar sesión
                                        </h1>
                                    </div>

                                    <div className="row g-3 mb-9">
                                        <div className="col-md-12">
                                            <a href="#" className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100">
                                                <img alt="Logo" src="/media/svg/brand-logos/microsoft-5.svg" className="h-15px me-3" />
                                                Inicia sesión con 365
                                            </a>
                                        </div>
                                    </div>

                                    <div className="separator separator-content my-14">
                                        <span className="w-125px text-gray-500 fw-semibold fs-7">O con email y contraseña</span>
                                    </div>



                                    {showAlert && (
                                       <div className="alert alert-danger d-flex align-items-center p-5">
                                           <i className="fa-solid fa-triangle-exclamation fs-1 text-danger me-4"><span className="path1"></span><span className="path2"></span></i>
                                       
                                           <div className="d-flex flex-column">
                                               <span className="mb-1 text-danger">Usuario o contraseña incorrecta</span>
                                       
                                           </div>
                                       </div>
                                    )}

                                    <div className="fv-row mb-8">
                                        <input 
                                            type="text" 
                                            placeholder="Email" 
                                            name="email" 
                                            onChange={(e) => setEmail(e.target.value)}
                                            autoComplete="on" 
                                            className="form-control bg-transparent" />
                                    </div>

                                    <div className="fv-row mb-3">
                                        <input 
                                        type="password" 
                                        placeholder="Contraseña" 
                                        onChange={(e) => setPassword(e.target.value)}
                                        autoComplete="on" 
                                        className="form-control bg-transparent" />
                                    </div>

                                    
                                    {/*
                                    <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                                        <div></div>
                                        <a href="" className="link-primary">
                                            Contraseña olvidada
                                        </a>
                                    </div>
                                    */}

                                    <div className="d-grid mb-10">
     
                                        <button type="submit" className="btn btn-primary" data-kt-indicator={`${isLoading ? 'on' : 'off'}`}>
                                            <span className="indicator-label">
                                                Iniciar sesión
                                            </span>
                                            <span className="indicator-progress">
                                                Autenticando... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>

        </>
    );
};

export default LoginPage;