import React, { createContext, useContext, useState } from 'react';

const PropertyContext = createContext();

export const PropertyProvider = ({ children }) => {

    const [selectedCategory, setSelectedCategory] = useState(null);

    //Gestion del contenido de los Alert
    const [message, setMessage] = useState('Esto un mensaje de prueba');
    const [alertType, setAlertType] = useState('success');


    //Gestion del Alert del Header
    const [showAlertHeader, setShowAlertHeader] = useState(false);
    const openAlertHeader = () => {
        setShowAlertHeader(true);
    };
    const closeAlertHeader = () => {
        setShowAlertHeader(false);
    };

    //Gestion del modal Nueva Categoria

    const [showNewCategoryModal, setShowNewCategoryModal] = useState(false);
    const openNewCategoryModal = () => {
        setShowNewCategoryModal(true);
    };
    const closeNewCategoryModal = () => {
        setShowNewCategoryModal(false);
    };

    const [showUpdateCategoryModal, setShowUpdateCategoryModal] = useState(false);
    const openUpdateCategoryModal = (category) => {
        setSelectedCategory(category);
        setShowUpdateCategoryModal(true);
    };
    const closeUpdateCategoryModal = () => {
        setShowUpdateCategoryModal(false);
    };
   

    const [apiSuccess, setApiSuccessSuccess] = useState(false);
    const changeApiSuccess = () => {
        setApiSuccessSuccess(!apiSuccess);
    };
    return (
        <PropertyContext.Provider value={{
            apiSuccess,
            changeApiSuccess,
            alertType,
            setAlertType,
            setMessage,
            message,
            openAlertHeader,
            closeAlertHeader,
            showAlertHeader,
            selectedCategory,
            setSelectedCategory,
            showNewCategoryModal,
            openNewCategoryModal,
            closeNewCategoryModal,
            showUpdateCategoryModal,
            openUpdateCategoryModal,
            closeUpdateCategoryModal,
        }}>
            {children}
        </PropertyContext.Provider>
    );
};

export const useProperty = () => {
    return useContext(PropertyContext);
};
