// api.js
import axios from 'axios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

// Crear una instancia de Axios
const axiosInstance = axios.create({
    timeout: 100000, // Tiempo de espera máximo para las solicitudes
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'accept': 'application/json'
    },
});

// Interceptor para añadir el token a cada solicitud
axiosInstance.interceptors.request.use(
    (config) => {
        const token = cookies.get('session_token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

const axiosInstanceDownload = axios.create({
    timeout: 0, // Tiempo de espera máximo para las solicitudes
});

export const postDownloadWithAuth = async (url, data) => {
    try {
        const response = await axiosInstanceDownload.post(url, data);
        return response.data;
    } catch (error) {
        return error.response;
    }
};


axiosInstanceDownload.interceptors.request.use(
    (config) => {
        const token = cookies.get('session_token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export const getWithAuth = async (url) => {
    try {
        const response = await axiosInstance.get(url);
        return response.data;
    } catch (error) {
        throw error;
    }
};

// Función para hacer una solicitud DELETE
export const deleteWithAuth = async (url) => {
    try {
        const response = await axiosInstance.delete(url);
        return response.data;
    } catch (error) {
        throw error;
    }
};

// Función para hacer una solicitud POST
export const post = async (url, data) => {
    try {
        const response = await axiosInstance.post(url, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

// Función para hacer una solicitud POST con autenticación
export const postWithAuth = async (url, data) => {
    try {
        const response = await axiosInstance.post(url, data);
        return response.data;
    } catch (error) {
        return error.response;
    }
};

// Función para hacer una solicitud PUT con autenticación
export const putWithAuth = async (url, data) => {
    try {
        const response = await axiosInstance.put(url, data);
        return response.data;
    } catch (error) {
        return error.response;
    }
};

// Función para hacer una solicitud POST con multipart/form-data
export const postWithAuthMultiPart = async (url, data) => {
    try {
        const response = await axiosInstance.post(url, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
        return response.data;
    } catch (error) {
        return error.response;
    }
};
