import React, { useContext, useEffect, useState } from 'react';
import { useSettings } from '../../settings/settingsContext';
import ModalNewSettings from '../components/modalNewSettings';


const NoSettingsPage = () => {

    const settingsContext = useSettings()


    return (
        <>

            <div className="card">
                <div className="card-body">
                    <div className="card-px text-center pt-15 pb-5">
                        <h2 className="fs-2x fw-bold mb-0">¡Configura tu conexión!</h2>
                        <p className="text-gray-500 fs-4 fw-semibold mt-7">
                            Configura tu conexión para empezar a recivir reservas</p>
                    </div>
                    <div class="text-center mb-4">
                        <a onClick={() => settingsContext.openNewSettingsModal()} class="btn btn-xl btn-primary">Crea tu primera configuración</a>
                    </div>
                    <div className="text-center pb-15 px-5">
                        <img src="/media/illustrations/sketchy-1/17.png" alt="" className="mw-100 h-200px h-sm-325px"></img>
                    </div>
                    
                </div>
            </div>
            {settingsContext.showNewSettingsModal && (
                <ModalNewSettings
                    onClose={() => settingsContext.closeNewSettingsModal()}
                />
            )}
        </>
    );

};

export default NoSettingsPage;