import React, { createContext, useContext, useState } from 'react';

const RuleContext = createContext();

export const RuleProvider = ({ children }) => {

    const [selectedRule, setSelectedRule] = useState(null);

    //Gestion del contenido de los Alert
    const [message, setMessage] = useState('Esto un mensaje de prueba');
    const [alertType, setAlertType] = useState('success');


    //Gestion del Alert del Header
    const [showAlertHeader, setShowAlertHeader] = useState(false);
    const openAlertHeader = () => {
        setShowAlertHeader(true);
    };
    const closeAlertHeader = () => {
        setShowAlertHeader(false);
    };

    //Gestion del modal Nueva Categoria

    const [showNewRuleModal, setShowNewRuleModal] = useState(false);
    const openNewRuleModal = () => {
        setShowNewRuleModal(true);
    };
    const closeNewRuleModal = () => {
        setShowNewRuleModal(false);
    };

    const [showUpdateRuleModal, setShowUpdateRuleModal] = useState(false);
    const openUpdateRuleModal = (rule) => {
        setSelectedRule(rule);
        setShowUpdateRuleModal(true);
    };
    const closeUpdateRuleModal = () => {
        setShowUpdateRuleModal(false);
    };
   

    const [apiSuccess, setApiSuccessSuccess] = useState(false);
    const changeApiSuccess = () => {
        setApiSuccessSuccess(!apiSuccess);
    };
    return (
        <RuleContext.Provider value={{
            apiSuccess,
            changeApiSuccess,
            alertType,
            setAlertType,
            setMessage,
            message,
            openAlertHeader,
            closeAlertHeader,
            showAlertHeader,
            selectedRule,
            setSelectedRule,
            showNewRuleModal,
            openNewRuleModal,
            closeNewRuleModal,
            showUpdateRuleModal,
            openUpdateRuleModal,
            closeUpdateRuleModal,
        }}>
            {children}
        </RuleContext.Provider>
    );
};

export const useRule = () => {
    return useContext(RuleContext);
};
