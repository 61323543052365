import React, { useContext, useRef, useEffect, useState } from 'react';
import ModalNewRoom from '../components/modalNewRoom';
import ModalUpdateRoom from '../components/modalUpdateRoom';
import Swal from 'sweetalert2'
import { API_URLS } from '../../api/apiConfig';
import { getWithAuth } from '../../api/api';
import { useParams } from "react-router-dom";
import { useCategory } from '../../property/categoryContext';


const PropertyCategoryPage = () => {

    let { categoryId } = useParams();

    const categoryContext = useCategory()

        const [roomsList, setListRooms] = useState([]);
        const [isLoading, setIsLoading] = useState(false);
        
        useEffect(() => {
            const fetchData = async () => {
                setIsLoading(true);
                try {
                    console.log("Get Rooms")
                    const data = await getWithAuth(API_URLS.GET_ROOMS(categoryId));
                    setListRooms(data.data);
                    console.log(roomsList)
                } catch (error) {
                    categoryContext.setMessage("Ha ocurrido un error conectando con el servidor")
                    categoryContext.setAlertType("danger")
                    categoryContext.openAlertHeader()
                    console.error('Error getting cards:', error);
                } finally {
                    setIsLoading(false);
                }
            };
            fetchData();
        }, []); //se indica este parametro para poder actualizar la api desde otro sitio


    return (
        <>
            <div className="d-flex flex-column flex-column-fluid">
                <div id="kt_app_toolbar" className="app-toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-fluid d-flex flex-stack">
                        <div className="page-title d-flex flex-column justify-content-center me-3 mb-6 mb-lg-0">
                            <h1
                                className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center me-3 mb-3 my-0">
                                Configuración de la categoría</h1>
                        </div>
                    </div>
                </div>

                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-fluid">
                        
                    {isLoading ? (
                            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                                <div className="spinner-grow text-color-amarillo-livensa" style={{ width: "5rem", height: "5em" }} role="status">
                                </div>
                            </div>
                        ) : (
                            <>           
                        <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1 pb-4">
                            <li className="breadcrumb-item text-muted">
                                <a href="" className="text-muted text-hover-primary">
                                    Habitación Doble                            </a>
                            </li>
                            <li className="breadcrumb-item">
                                <span className="bullet bg-gray-500 w-5px h-2px"></span>
                            </li>
                            <li className="breadcrumb-item text-muted">
                                Habitaciones                                            </li>

                        </ul>
                        <div className="card">
                            <div className="card-header border-0 pt-6">
                                <div className="card-title">
                                    <div className="d-flex align-items-center position-relative my-1">
                                        <i className="ki-outline ki-magnifier fs-3 position-absolute ms-5"></i>
                                        <input type="text" data-kt-user-table-filter="search" className="form-control form-control-solid w-250px ps-13" placeholder="Habitación">
                                        </input>
                                    </div>
                                </div>
                                <div className="card-toolbar">
                                    <div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
                                        <a href="#" className="btn btn-sm btn-primary"onClick={() => categoryContext.openNewRoomModal()}>
                                            <i className="icon-in-button-color ki-outline ki-plus"></i>
                                            Nueva habitación</a>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body py-4">
                                <div id="kt_table_users_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer"><div className="table-responsive"><table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_table_users">
                                    <thead>
                                        <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">

                                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_table_users" rowspan="1" colspan="1">
                                                Número de Habitación
                                            </th>
                                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_table_users" rowspan="1" colspan="1" >
                                                Nombre de habitación
                                            </th>
                                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_table_users" rowspan="1" colspan="1" >Código RR
                                            </th>
                                            <th className="min-w-125px sorting" tabIndex="0" aria-controls="kt_table_users" rowspan="1" colspan="1" >Rango de personas
                                            </th>
                                            <th className="min-w-125px sorting" rowspan="1" colspan="1" aria-label="Actions">Acciones
                                            </th>
                                        </tr>
                                    </thead>
                                    
                                    <tbody className="text-gray-600 fw-semibold">
                                    {roomsList.map((room, index) => (
                                        <tr key={index}>
                                            <td>
                                                {room.number}
                                            </td>
                                            <td>
                                            {room.name}
                                            </td>
                                            <td>
                                            {room.rr_code}
                                            </td>
                                            <td>
                                            {room.min_capacity} - {room.max_capacity}
                                            </td>
                                            <td className="">
                                                <TableActions
                                                    room={room}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table></div><div className="row"><div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"></div><div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end"><div className="dataTables_paginate paging_simple_numbers" id="kt_table_users_paginate"><ul className="pagination"><li className="paginate_button page-item previous disabled" id="kt_table_users_previous"><a href="#" aria-controls="kt_table_users" data-dt-idx="0" tabIndex="0" className="page-link"><i className="previous"></i></a></li><li className="paginate_button page-item active"><a href="#" aria-controls="kt_table_users" data-dt-idx="1" tabIndex="0" className="page-link">1</a></li><li className="paginate_button page-item "><a href="#" aria-controls="kt_table_users" data-dt-idx="2" tabIndex="0" className="page-link">2</a></li><li className="paginate_button page-item "><a href="#" aria-controls="kt_table_users" data-dt-idx="3" tabIndex="0" className="page-link">3</a></li><li className="paginate_button page-item next" id="kt_table_users_next"><a href="#" aria-controls="kt_table_users" data-dt-idx="4" tabIndex="0" className="page-link"><i className="next"></i></a></li></ul></div></div></div></div>
                            </div>

                        </div>
                    </>
                    )}
                    </div>
                </div>
            </div>
            {categoryContext.showNewRoomModal && (
                <ModalNewRoom
                    onClose={() => categoryContext.closeNewRoomModal()}
                    categoryId={categoryId}
                />
            )}
            {categoryContext.showUpdateRoomModal && (
                <ModalUpdateRoom
                    onClose={() => categoryContext.closeUpdateRoomModal()}
                    room={categoryContext.selectedRoom}
                />
            )}

        </>
    );

};


const TableActions = ({room}) => {
    const [showMenu, setShowMenu] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
    const menuRef = useRef(null);
    const anchorRef = useRef(null);

    const categoryContext = useCategory()

    const [categoriesList, setListCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);


    const toggleDropdown = () => {
        setShowMenu(!showMenu);
    };

    const handleDelete = () => {
        Swal.fire({
            title: '¡Cuidado!',
            text: 'Borrar una habitación puede implicar cambios actuales en el panorama general de limpiezas',
            icon: 'info',
            confirmButtonText: `
                Borrar habitación
            `,
            customClass: {
                confirmButton: 'btn btn-sm btn-primary',
              },
            showCancelButton: false,
        });
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowMenu(false);
            }
        };

        if (showMenu) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showMenu]);

    useEffect(() => {
        if (showMenu) {
            const anchorRect = anchorRef.current.getBoundingClientRect();
            const elementWidth = anchorRef.current.offsetWidth;
            const screenWidth = window.innerWidth;
            const menuX = anchorRect.left - screenWidth + elementWidth;
            const menuY = anchorRect.top + anchorRect.height + 10;
            setMenuPosition({ x: menuX, y: menuY });
        }
    }, [showMenu]);

    return (
        <>
            <a
                href="#"
                className="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary show menu-dropdown"
                onClick={toggleDropdown}
                data-kt-menu-trigger="click"
                data-kt-menu-placement="bottom-end"
                ref={anchorRef} // Referencia al elemento anchor
            >
                Acciones
                <i className="ki-outline ki-down text-color-azul-livensa fs-5 ms-1"></i>
            </a>
            <div
                ref={menuRef}
                style={{ transform: `translate3d(${menuPosition.x}px, ${menuPosition.y}px, 0)` }}
                className={`menu-actions-table menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4 ${showMenu ? 'show' : ''}`}
                data-kt-menu="true"
                data-popper-placement="bottom-end"
            >
                <div className="menu-item px-3">
                    <a onClick={() => categoryContext.openUpdateRoomModal(room)} className="menu-link px-3">
                        Editar
                    </a>
                </div>
                <div className="menu-item px-3">
                    <a onClick={handleDelete} className="menu-link px-3" data-kt-ecommerce-product-filter="delete_row">
                        Eliminar
                    </a>
                </div>
            </div>
        </>
    );
};


export default PropertyCategoryPage;