import React from 'react';
import Swal from 'sweetalert2';
import { postWithAuth } from '../../api/api';
import { API_URLS } from '../../api/apiConfig';

class ModalResetRules extends React.Component {
    static handleReset = async () => {
        const resetApi = async () => { // Definir deleteApi aquí como una función estática interna
            try {
                await postWithAuth(API_URLS.RESET_RULES);
            } catch (error) {
                console.error('Error deleting rule:', error);
                throw error;
            }
        };

        const result = await Swal.fire({
            title: '¡Cuidado!',
            text: 'Resetear las reglas de limpieza puede implicar cambios actuales en el panorama general de limpiezas',
            icon: 'info',
            confirmButtonText: `
                Resetear reglas
            `,
            customClass: {
                confirmButton: 'btn btn-sm btn-primary',
              },
            showCancelButton: false,
        });

        if (result.isConfirmed) {
            try {
                Swal.showLoading();
                await resetApi(); // Utilizar la función deleteApi aquí
                window.location.reload();
            } catch (error) {
                console.error('Error reseting rules:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error al resetear las reglas de limpieza',
                    text: 'Hubo un problema reseteando las reglas de limpieza',
                });
            }
        }
    };

    render() {
        return null;
    }
}

export default ModalResetRules;
