import React from 'react';
import Swal from 'sweetalert2';
import { getWithAuth } from '../../api/api';
import { API_URLS } from '../../api/apiConfig';

class ModalCleaningsAllDay extends React.Component {
    static handleReset = async () => {
        const resetApi = async () => {
            try {
                await getWithAuth(API_URLS.APPLY_CLEANINGS_ALL_DAY);
            } catch (error) {
                console.error('Error applying cleaning rules:', error);
                throw error;
            }
        };

        const result = await Swal.fire({
            title: '¡Cuidado!',
            text: 'Establecer reglas de limpieza todos los días afectará al panorama de limpiezas de tu propiedad',
            icon: 'info',
            confirmButtonText: 'Limpieza todos los días',
            customClass: {
                confirmButton: 'btn btn-sm btn-primary',
            },
            showCancelButton: false,
            preConfirm: async () => {
                try {
                    Swal.showLoading();
                    await resetApi();
                    window.location.reload();
                } catch (error) {
                    console.error('Error reseting rules:', error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Error al crear las reglas de limpieza',
                        text: 'Hubo un problema creando las reglas de limpieza',
                    });
                    throw error;
                }
            }
        });

        //if (!result.isConfirmed) {
        //    Swal.hideLoading();
        //}
    };

    render() {
        return null;
    }
}

export default ModalCleaningsAllDay;
