import React, { useContext, useEffect, useState, useRef, useCallback } from 'react';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import resourceDayGrid from '@fullcalendar/resource-daygrid'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import interactionPlugin from '@fullcalendar/interaction';
import ModalExport from '../components/modalExport';
import ModalCleaningUpdate from '../components/modalCleaningUpdate';
import ModalCleaningCreate from '../components/modalCleaningCreate';
import { usePlanification } from '../../planification/planificationContext';
import { API_URLS } from '../../api/apiConfig';
import { getWithAuth } from '../../api/api';
import ModalCleaningCreateFromCalendar from '../components/modalCleaningCreateFromCalendar';
import ModalBookingCreate from '../components/modalBookingCreate';
import ModalBookingUpdate from '../components/modalBookingUpdate';
import ModalChangeStatusCleaning from '../components/modalChangeCleaningStatus';
import CalendarComponent from '../components/calendar';
import Form from "react-bootstrap/Form";


const PlanificationPage = () => {

    const planificationContext = usePlanification()

    return (
        <>
            <div className="d-flex flex-column flex-column-fluid">

                <div id="kt_app_toolbar" className="app-toolbar">

                    <div id="kt_app_toolbar_container" className="app-container container-fluid d-flex flex-stack">

                        <div className="page-title d-flex flex-column justify-content-center me-3 mb-6 mb-lg-0">

                            <h1
                                className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center me-3 mb-3 my-0">
                                Planificación</h1>
                        </div>
                    </div>
                </div>

                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-fluid">

                        
                        <div className="row g-5 g-xl-10 mb-xl-10">
                            <div className="col-lg-12 col-xl-12 col-xxl-12 mb-5 mb-xl-0">
                                <TimelineSection/>
                            </div>
                        </div>
                        <div className="row g-5 g-xl-10 mb-xl-10">
                            <div className="col-lg-12 col-xl-12 col-xxl-12 mb-5 mb-xl-0">
                                <CalendarSection/>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {planificationContext.showExportCleaningModal && 
            <ModalExport
            onClose={() => planificationContext.closeExportCleaningModal()} 
            />
            }
        </>
    );

};

export default PlanificationPage;


const CalendarSection = () => {

    const calendarRef = useRef(null)
    const planificationContext = usePlanification()
    const [habitaciones, setHabitaciones] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date(startDate.getTime() + 7 * 24 * 60 * 60 * 1000));

    return (
        <>  
            <div className="card card-calendar-height">
                <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold text-gray-900">
                <div className="d-flex flex-column mb-5 fv-row fv-plugins-icon-container">
                    <div className="d-flex"> {/* Div para colocar los FormControl en una fila */}
                        <Form.Control 
                            className="m-3 d-flex align-items-center" 
                            placeholder='Fecha de inicio' 
                            name="start" 
                            value={startDate.toISOString().split('T')[0]} // Convertir startDate a 'YYYY-MM-DD'
                            onChange={(e) => setStartDate(new Date(e.target.value))} // Actualizar el estado startDate cuando cambie la entrada
                            type="date" 
                        />
                        <Form.Control 
                            className="m-3 d-flex align-items-center" 
                            placeholder='Fecha de fin' 
                            name="end" 
                            value={endDate.toISOString().split('T')[0]} // Convertir startDate a 'YYYY-MM-DD'
                            onChange={(e) => setEndDate(new Date(e.target.value))} // Actualizar el estado startDate cuando cambie la entrada
                            type="date" 
                        />
                        {/* Nuevo FormControl */}
                        <Form.Control 
                            className="m-3 d-flex align-items-center" 
                            placeholder='Id de Reserva' 
                            name="other" 
                            // Aquí establece el valor y el onChange según tus necesidades
                            type="text" 
                        /> 
                    </div>
                </div>
            </span>
        </h3>

                    <div className="card-toolbar">
                        <a className="m-3 btn btn-sm btn-primary" onClick={() => planificationContext.openNewCleaningModal()}>Nueva Limpieza</a>
                        <a className="btn btn-sm btn-primary" onClick={() => planificationContext.openExportCleaningModal()}>Exportar</a>
                    </div>
                </div>
                    <div className="card-body border-0 pt-5">
                    <CalendarComponent 
                        startDate={startDate}
                        endDate={endDate}
                    />
                    </div>
                  
            </div>
            
            {planificationContext.showUpdateCleaningModal && 
            <ModalCleaningUpdate
            onClose={() => planificationContext.closeUpdateCleaningModal()} 
            cleaning_id={planificationContext.selectedCleaning}
            />
            }

            {planificationContext.showUpdateBookingModal && 
            <ModalBookingUpdate
            onClose={() => planificationContext.closeUpdateBookingModal()} 
            rooms={habitaciones}
            reservation_id={planificationContext.selectedBooking}
            />
            }

            {planificationContext.showNewCleaningModal && 
            <ModalCleaningCreate 
            onClose={() => planificationContext.closeNewCleaningModal()} 
            rooms={habitaciones}
            />
            }

            {planificationContext.showNewBookingModal && 
            <ModalBookingCreate 
            onClose={() => planificationContext.closeNewBookingModal()} 
            rooms={habitaciones}
            />
            }

            {planificationContext.showNewCleaningModalFromCalendar && 
            <ModalCleaningCreateFromCalendar
            onClose={() => planificationContext.closeNewCleaningModalFromCalendar()} 
            date={planificationContext.selectedDate}
            roomTitle={planificationContext.selectedRoomTitle}
            roomId={planificationContext.selectedRoomId}
            />
            }   

        </>
        );
}



const TimelineSection = () => {

    const [isLoading, setIsLoading] = useState(false);
    const today = new Date();
    const dayList = [];
    let lastDay = new Date();
    const planificationContext = usePlanification()
    const [cleaningsList, setListCleanings] = useState([]);
    const [cleaningRepaso, setCleaningRepaso] = useState([]);
    const [cleaningTextil, setCleaningTextil] = useState([]);
    const [cleaningCheckout, setCleaningCheckout] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);


    for (let i = 0; i < 10; i++) {
        const date = new Date(today);
        date.setDate(today.getDate() + i);
        dayList.push(date.toDateString());
        lastDay = date
    }

    function fetchDataWithDates() {
        let dateTimeline = planificationContext.dateTimelineActive;
        console.log(dateTimeline)
        dateTimeline = new Date(dateTimeline + 'Z');
        const date_timeline_formatted = dateTimeline.toISOString().split('T')[0];
        console.log(date_timeline_formatted)
        return getWithAuth(API_URLS.GET_TIMELINE(date_timeline_formatted));
    }

    async function fetchData() {
        setIsLoading(true);
        try {
            const cleanings = await fetchDataWithDates();
            console.log("CLeanings",cleanings)
            setListCleanings(cleanings.data[0].list_cleanings);
            setCleaningRepaso(cleanings.data[0].total_repaso);
            setCleaningTextil(cleanings.data[0].total_textil);
            setCleaningCheckout(cleanings.data[0].total_checkout);
        } catch (error) {
            console.error('Error getting cleanings:', error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        const fetchDataAll = async () => {
            await fetchData();
            setDataLoaded(true);
        };
        fetchDataAll();
    }, [planificationContext.dateTimelineActive]);


    const activeClass = (day) => {
        return day === planificationContext.dateTimelineActive ? 'active' : '';
      };

    const handleChangeStatus = (cleaning_id) => {
        ModalChangeStatusCleaning.handleChangeStatus(cleaning_id);
    };

    return (
        <>  
             <div className="card h-md-100">

                    <div className="card-header border-0 pt-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold text-gray-900">Timeline (Cliente: {cleaningRepaso} Checkout: {cleaningCheckout} Textil: {cleaningTextil})</span>
                        </h3>

                        <div className="card-toolbar">
                            <a onClick={() => planificationContext.openExportCleaningModal()} className="btn btn-sm btn-primary">Exportar</a>
                        </div>

                    </div>
                    <div className="card-body pt-7 px-0">
                    {dataLoaded && isLoading ? (
                            <div className="d-flex justify-content-center align-items-center" style={{ height: '10vh' }}>
                                <div className="spinner-grow text-color-amarillo-livensa" style={{ width: "5rem", height: "5em" }} role="status">
                                </div>
                            </div>
                        ) : (
                        <>
                        <ul className="nav nav-stretch nav-pills nav-pills-custom nav-pills-active-custom d-flex justify-content-between mb-8 px-5">

                            {dayList.map((day, index) => (
                                <li key={index} className="nav-item p-0 ms-0">
                                <a onClick={() => planificationContext.setDateTimelineActive(day)} className={`nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3  btn-active-danger ${activeClass(day)}`} data-bs-toggle="tab">
                                    <span className="fs-7 fw-semibold">{day.substring(0, 3)}</span>
                                    <span className="fs-6 fw-bold">{day.substring(8, 10)}</span>
                                </a>
                                </li>
                            ))}
                        </ul>
                        
                        <div className="tab-content mb-2 px-9">
                            <div className="tab-pane fade show active fixed-height-scroll" id="timeline_content">
                            {cleaningsList.map((cleaning, index) => {
                                let bgColorClass = '';

                                switch (cleaning.cleaning_type) {
                                    case 'Checkin':
                                        bgColorClass = 'bg-green';
                                        break;
                                    case 'Checkout':
                                        bgColorClass = 'bg-grey';
                                        break;
                                    case 'Repaso':
                                        bgColorClass = 'bg-azul-claro';
                                        break;
                                    default:
                                        break;
                                }

                                return (
                                    <div key={index} className="d-flex align-items-center mb-6">
                                        <span data-kt-element="bullet" className={`bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 ${bgColorClass}`}>
                                        </span>
                                        <div className="flex-grow-1 me-5">
                                            <div className="text-gray-800 fw-semibold fs-2">{cleaning.room.number} {cleaning.room.name}
                                            {cleaning.is_forced === true ? (
                                                <span className="text-gray-500 fw-semibold fs-7 m-4">Creada exteriormente</span>
                                            ) : (
                                                <span className="text-gray-500 fw-semibold fs-7 m-4">Creada por algoritmo</span>
                                            )}
                                            
                                            </div>
                                            <div className="text-gray-700 fw-semibold fs-6">{cleaning.cleaning_type}</div>
                                            <div className="text-gray-500 fw-semibold fs-7">{cleaning.status}
                                            </div>
                                        </div>
                                        {cleaning.status === 'Por hacer' ? (
                                            <a onClick={() => handleChangeStatus(cleaning.id)} className="btn btn-sm btn-primary">Realizado</a>
                                        ) : (
                                            <a onClick={() => handleChangeStatus(cleaning.id)} className="btn btn-sm btn-secondary">No Realizado</a>
                                        )}
                                    </div>
                                );
                            })}
                            </div>
                        </div>
                        </>
                        )}
                    </div>

                    </div>


        </>
        );
}
