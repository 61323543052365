import React, { useContext, useState } from 'react';


const Footer = () => {

    return (
        <>
            <div id="kt_app_footer" className="app-footer">

                <div className="app-container container-fluid d-flex flex-column flex-md-row flex-center flex-md-stack py-3">

                    <div className="text-gray-900 order-2 order-md-1">
                        <span className="text-muted fw-semibold me-1">2023&copy;</span>
                        <a href="" target="_blank" className="text-gray-800 text-hover-primary">Wofy HouseKeeping</a>
                    </div>

                </div>

            </div>
        </>
    );

};

export default Footer;