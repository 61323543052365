import React from 'react';
import { useState } from 'react';
import Form from "react-bootstrap/Form";
import { Button, Modal } from 'react-bootstrap';
import { API_URLS } from '../../api/apiConfig';
import { postDownloadWithAuth } from '../../api/api';

const ModalExport = ({ onClose }) => {


    const [isLoading, setIsLoading] = useState(false);
    const [alertMessage, setShowAlertMessage] = useState("");
    const [showAlert, setIshowAlert] = useState(false);
    const [urlFile, setUrlFile] = useState(null);
    const date_now = new Date()
    const dateNowFormatted = date_now.toISOString().split('T')[0];

    const [exportData, setExportData] = useState({
        date_start: dateNowFormatted,
        date_end: dateNowFormatted,
        type_export: "Rooms",
        format: "CSV"

    });

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        
        setExportData((prevExportData) => {
            const exportData = {
                ...prevExportData,
                [name]: type === 'checkbox' ? !prevExportData[name] : value,
            };
            return exportData;
        });

        console.log(exportData)
    };

    const dowloadExport = async () => {
        try {
        setIsLoading(true)
          const response = await postDownloadWithAuth(API_URLS.EXPORT_PLANNING, exportData);
          if (response.data) {
            setIsLoading(false)
            setUrlFile(response.data)
            console.log(response)
          } else {
            setIsLoading(false);
            setIshowAlert(true);
            setShowAlertMessage("Error al descargar el PD");
          }
        } catch (error) {
            setIsLoading(false);
            setIshowAlert(true);
            setShowAlertMessage("Error al descargar el PD");
        }
      };

    const handleSave = async (e) => {
        e.preventDefault();

        try {
            dowloadExport();
        } catch (error) {
            console.error('Error creating user admin:', error);
        }
    };

    return (
        <>
            <Modal show={true} onHide={onClose} size="xl" centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Exportar planificacion</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                        <form id="kt_modal_create_api_key_form" className="form fv-plugins-bootstrap5 fv-plugins-framework" action="#" data-select2-id="select2-data-kt_modal_create_api_key_form">
                            <div className="modal-body py-10 px-lg-17" data-select2-id="select2-data-108-9vb6">
                                <div className="scroll-y me-n7 pe-7" id="kt_modal_create_api_key_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_create_api_key_header" data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll" data-kt-scroll-offset="300px" data-select2-id="select2-data-kt_modal_create_api_key_scroll">
                                    <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-10 p-6">
                                        <i className="ki-outline ki-information fs-2tx text-warning me-4"></i>
                                        <div className="d-flex flex-stack flex-grow-1 ">
                                            <div className=" fw-semibold">
                                                <h4 className="text-gray-900 fw-bold">Atención!</h4>
                                                <div className="fs-6 text-gray-700 ">Este reporte captura los datos actualizados de este momento</div>
                                            </div>
                                        </div>
                                    </div>
                                    {showAlert && (
                                        <div className="alert alert-danger d-flex align-items-center p-5">
                                        <i className="fa-solid fa-triangle-exclamation fs-1 text-danger me-4"><span className="path1"></span><span className="path2"></span></i>
                                    
                                        <div className="d-flex flex-column">
                                            <span className="mb-1 text-danger">Error exportando el documento</span>
                                    
                                        </div>
                                    </div>
                                    )}
                                    <div className="d-flex flex-column mb-5 fv-row fv-plugins-icon-container">
                                        <label for="exampleFormControlInput1" className="form-label">Fecha de inicio</label>
                                        <div>
                                            <Form.Control className="d-flex align-items-center" name="date_start" onChange={handleInputChange} defaultValue={dateNowFormatted} placeholder='Selecciona mes' type="date" />
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                                        <label for="exampleFormControlInput1" className="form-label">Fecha de fin</label>
                                        <div>
                                            <Form.Control className="d-flex align-items-center" name="date_end"  onChange={handleInputChange} defaultValue={dateNowFormatted} placeholder='Selecciona mes' type="date" />
                                        </div>
                                    </div>

                                    <div className="d-flex flex-column mb-5 fv-row fv-plugins-icon-container mb-4">
                                        <label for="exampleFormControlInput1" className="form-label">Tipo de exportación</label>
                                        <select className="form-select" name="type_export" onChange={handleInputChange} defaultValue="Rooms" aria-label="Select example">
                                            <option value="Rooms">Por habitaciones</option>
                                            <option value="Forecast1">Forecast por fechas (columnas)</option>
                                            <option value="Forecast2">Forecast por fechas (filas)</option>
                                        </select>
                                    </div>
                                    {urlFile != null && (
                                    <>
                                    <center><a href={urlFile.file_url} className="btn btn-primary">
                                        <img alt="Logo" src="/media/svg/files/xml.svg" class="h-30px me-3 "></img>Descarga CSV
                                    </a></center>
                                    </>
                                     )}
                                </div>
                            </div>

                            <div className="modal-footer flex-center">
                                
                                <button type="reset" id="kt_modal_create_api_key_cancel" className="btn btn-light me-3">
                                    Cancelar
                                </button>

                                <button type="submit" onClick={handleSave} className="btn btn-primary" data-kt-indicator={`${isLoading ? 'on' : 'off'}`}>
                                    <span className="indicator-label">
                                        Generar archivo
                                    </span>
                                    <span className="indicator-progress">
                                        Generando ... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </div>
                        </form>
                </Modal.Body>
            </Modal>

        </>

    );
};

export default ModalExport;