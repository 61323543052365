import React, { useContext, useRef, useEffect, useState } from 'react';
import ModalNewRule from '../components/modalNewRule';
import { useRule } from '../../rules/ruleContext';
import { API_URLS } from '../../api/apiConfig';
import AutoDismissAlert from '../../components/autoDismiss';
import { getWithAuth } from '../../api/api';
import ModalUpdateRule from '../components/modalUpdateRule';
import ModalDeleteRule from '../components/modalDeleteRule';
import ModalResetRules from '../components/modalResetRules';
import ModalCleaningsAllDay from '../components/modalCleaningsAllDay';

const RulesPage = () => {

    const ruleContext = useRule()

    const [rulesList, setListRules] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                console.log("Get Rules")
                const data = await getWithAuth(API_URLS.GET_RULES);
                setListRules(data.data);
                console.log(rulesList)
            } catch (error) {
                ruleContext.setMessage("Ha ocurrido un error conectando con el servidor")
                ruleContext.setAlertType("danger")
                ruleContext.openAlertHeader()
                console.error('Error getting cards:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleClickReset = () => {
        ModalResetRules.handleReset();
    };

    const handleClickApplyCleaningsAllDays = () => {
        ModalCleaningsAllDay.handleReset();
    };


    return (
        <>
            <div className="d-flex flex-column flex-column-fluid">
                <div id="kt_app_toolbar" className="app-toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-fluid d-flex flex-stack">
                        <div className="page-title d-flex flex-column justify-content-center me-3 mb-6 mb-lg-0">
                            <h1
                                className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center me-3 mb-3 my-0">
                                Configuración y Reglas de limpieza</h1>
                        </div>
                    </div>
                </div>

                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-fluid">
                    {ruleContext.showAlertHeader && (
                            <div className="">
                                <AutoDismissAlert message={ruleContext.message} alertType={ruleContext.alertType} onClose={ruleContext.closeAlertHeader} />
                            </div>
                        )}
                        {isLoading ? (
                            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                                <div className="spinner-grow text-color-amarillo-livensa" style={{ width: "5rem", height: "5em" }} role="status">
                                </div>
                            </div>
                        ) : (
                            <>
                        <div className="card">
                            <div className="card-header border-0 pt-6">
                                <div className="card-title">
                                    <div className="d-flex align-items-center position-relative my-1">
                                        
                                    </div>
                                </div>
                                <div className="card-toolbar">
                                    <div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
                                    <a onClick={() => handleClickApplyCleaningsAllDays()} className="btn btn-sm btn-primary mx-2">
                                            <i className="icon-in-button-color fa-solid fa-broom"></i>
                                            Aplicar limpiezas todos los días</a>
                                        <a onClick={() => ruleContext.openNewRuleModal()} className="btn btn-sm btn-primary">
                                            <i className="icon-in-button-color ki-outline ki-plus"></i>
                                            Nueva regla</a>
                                            <a onClick={() => handleClickReset()} className="btn btn-sm btn-primary mx-2">
                                            <i className="icon-in-button-color bi bi-x-circle"></i>
                                            Resetear reglas</a>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body py-4">
                                <div id="kt_table_users_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer"><div className="table-responsive"><table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_table_users">
                                    <thead>
                                        <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">

                                            <th className="" tabIndex="0" aria-controls="kt_table_users" rowSpan="1" colSpan="1" aria-label="User: activate to sort column ascending">
                                                Número de noches
                                            </th>
                                            <th className="" tabIndex="0" aria-controls="kt_table_users" rowSpan="1" colSpan="1" >Días de limpieza
                                            </th>
                                            <th className="" tabIndex="0" aria-controls="kt_table_users" rowSpan="1" colSpan="1" >Limpiezas totales
                                            </th>
                                            <th className="" tabIndex="0" aria-controls="kt_table_users" rowSpan="1" colSpan="1" >Días de sábana/toallas
                                            </th>
                                            <th className="" tabIndex="0" aria-controls="kt_table_users" rowSpan="1" colSpan="1" >Cambios totales
                                            </th>
                                            <th className="" tabIndex="0" aria-controls="kt_table_users" rowSpan="1" colSpan="1" >Limpieza en check-out
                                            </th>
                                            <th className="" rowSpan="1" colSpan="1" aria-label="Actions">Acciones
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-gray-600 fw-semibold">
                                    {rulesList.map((rule, index) => (
                                        <tr key={index}>
                                            <td>
                                                {rule.number_of_nights}
                                            </td>
                                            <td>
                                            {rule.cleaning_days.map((rule_day, index) => (
                                                <div key={index}  className="badge badge-light fw-bold">{rule_day}</div>
                                                ))}
                                            </td>
                                            <td>
                                            {rule.total_cleanings}
                                            </td>
                                            <td>
                                            {rule.towel_days.map((towel_day, index) => (
                                                <div key={index}  className="badge badge-light fw-bold">{towel_day}</div>
                                                ))}
                                            </td>
                                            <td>
                                            {rule.total_towel_days}
                                            </td>
                                            <td>
                                                {rule.check_out_cleaning ? (
                                                    "Si"
                                                ) : (
                                                    "No"
                                                )}
                                            </td>
                                            <td className="">
                                                <TableActions 
                                                    rule={rule}
                                                />

                                            </td>
                                        </tr>
                                    
                                     ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                                </div>
                                <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                                    <div className="dataTables_paginate paging_simple_numbers" id="kt_table_users_paginate">
                                       
                                    </div>
                                </div>
                                </div>
                                </div>
                        </div>

                        </div>
                     </>
                     )}
                    </div>
                </div>
            </div>
            {ruleContext.showNewRuleModal && (
                <ModalNewRule
                    onClose={() => ruleContext.closeNewRuleModal()}
                />
            )}
            {ruleContext.showUpdateRuleModal && (
                <ModalUpdateRule
                    onClose={() => ruleContext.closeUpdateRuleModal()}
                    rule={ruleContext.selectedRule}
                />
            )}

        </>
    );

};

export default RulesPage;


const TableActions = ({rule}) => {

    const [showMenu, setShowMenu] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
    const menuRef = useRef(null);
    const anchorRef = useRef(null);
    const ruleContext = useRule()
    const [showAlert, setIshowAlert] = useState(false);
    const [message, setMessage] = useState();
    const [isLoading, setIsLoading] = useState(false);


    const toggleDropdown = () => {
        setShowMenu(!showMenu);
    };

    
    const handleClickDelete = () => {
        ModalDeleteRule.handleDelete(rule.id);
    };


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowMenu(false);
            }
        };

        if (showMenu) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showMenu]);

    useEffect(() => {
        if (showMenu) {
            // Obtenemos las coordenadas del elemento anchor
            const anchorRect = anchorRef.current.getBoundingClientRect();
            const elementWidth = anchorRef.current.offsetWidth;
            // Calculamos la posición del menú
            const screenWidth = window.innerWidth;
            const menuX = anchorRect.left - screenWidth + elementWidth;
            const menuY = anchorRect.top + anchorRect.height + 10;
            setMenuPosition({ x: menuX, y: menuY });
        }
    }, [showMenu]);

    return (
        <>
            <a
                href="#"
                className="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary show menu-dropdown"
                onClick={toggleDropdown}
                data-kt-menu-trigger="click"
                data-kt-menu-placement="bottom-end"
                ref={anchorRef} // Referencia al elemento anchor
            >
                Acciones
                <i className="ki-outline ki-down text-color-azul-livensa fs-5 ms-1"></i>
            </a>
            <div
                ref={menuRef}
                style={{ transform: `translate3d(${menuPosition.x}px, ${menuPosition.y}px, 0)` }}
                className={`menu-actions-table menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4 ${showMenu ? 'show' : ''}`}
                data-kt-menu="true"
                data-popper-placement="bottom-end"
            >
                <div className="menu-item px-3">
                    <a onClick={() => ruleContext.openUpdateRuleModal(rule)} className="menu-link px-3">
                        Editar
                    </a>
                </div>
                <div className="menu-item px-3">
                    <a onClick={handleClickDelete} className="menu-link px-3" data-kt-ecommerce-product-filter="delete_row">
                        Eliminar
                    </a>
                </div>
            </div>
        </>
    );
};
