import React from 'react';
import { useState } from 'react';
import Form from "react-bootstrap/Form";
import { Button, Modal } from 'react-bootstrap';
import { putWithAuth } from '../../api/api';
import { API_URLS } from '../../api/apiConfig';
import { useNavigate } from 'react-router-dom';


const ModalUpdateRoom = ({ onClose, room}) => {

    const navigate = useNavigate();
    const [showAlert, setIshowAlert] = useState(false);
    const [alertMessage, setShowAlertMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [updateRoom, setRoom] = useState(room);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;

        setRoom((prevRoom) => {
            const updateRoom = {
                ...prevRoom,
                [name]: type === 'checkbox' ? !prevRoom[name] : value,
            };
            return updateRoom;
        });

        console.log(updateRoom)
    };

    const editRoom = async (e) => {

        const formData = new FormData();
        formData.append('data', JSON.stringify(updateRoom));
        try {
            setIsLoading(true)
            const response = await putWithAuth(
                API_URLS.EDIT_ROOM(room.id),
                updateRoom
            );
            console.log("response", response)
            if (response.message === 'success') {
                setIsLoading(false)
                window.location.reload();
            } else if (response.message === 'error') {
                setIsLoading(false)
                setIshowAlert(true);
                setShowAlertMessage(response.detail)
            } else {
                setIsLoading(false)
                setIshowAlert(true);
                setShowAlertMessage("Ha ocurrido un error")
            }
        } catch (error) {
            console.error('Error creating user admin:', error);
        }

    };

    const handleSave = async (e) => {
        e.preventDefault();

        try {
            editRoom();
        } catch (error) {
            console.error('Error creating user admin:', error);
        }
    };


    return (
        <>
            <Modal show={true} onHide={onClose} size="xl" centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Editar Habitación</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <form className="form fv-plugins-bootstrap5 fv-plugins-framework" data-select2-id="select2-data-kt_modal_create_api_key_form">
                <div className="modal-body py-10 px-lg-17" data-select2-id="select2-data-108-9vb6">
                    <div className="scroll-y me-n7 pe-7" id="kt_modal_create_api_key_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_create_api_key_header" data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll" data-kt-scroll-offset="300px" data-select2-id="select2-data-kt_modal_create_api_key_scroll">
                        <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-10 p-6">
                            <i className="ki-outline ki-information fs-2tx text-warning me-4"></i>
                            <div className="d-flex flex-stack flex-grow-1 ">
                                <div className=" fw-semibold">
                                    <h4 className="text-gray-900 fw-bold">Atención!</h4>
                                    <div className="fs-6 text-gray-700 ">Agregar o editar una habitación puede tener consecuencias en el resultado final de limpiezas</div>
                                </div>
                            </div>
                        </div>
                        {showAlert && (
                            <div className="alert alert-danger d-flex align-items-center p-5">
                                <i className="fa-solid fa-triangle-exclamation fs-1 text-danger me-4"><span className="path1"></span><span className="path2"></span></i>
                                <div className="d-flex flex-column">
                                    <span className="mb-1 text-danger">{alertMessage}</span>
                                </div>
                            </div>
                            )}
                        <div className="row g-9 mb-8">
                            <div className="col-md-6 fv-row fv-plugins-icon-container">
                                <label className="required fs-6 fw-semibold mb-2">Número de la habitación</label>
                                <input 
                                type="text" 
                                className="form-control form-control-solid" 
                                placeholder="12" 
                                name="number"
                                onChange={handleInputChange}
                                defaultValue={room.number}
                                />
                            </div>
                            <div className="col-md-6 fv-row fv-plugins-icon-container">
                                <label className="required fs-6 fw-semibold mb-2">Nombre de la habitación</label>
                                <input 
                                type="text" 
                                className="form-control form-control-solid" 
                                placeholder="Doble con vistas al mar y baño" 
                                name="name"
                                onChange={handleInputChange}
                                defaultValue={room.name}
                                />
                            </div>
                        </div>

                        <div className="row g-9 mb-8">
                        <div className="col-md-4 fv-row fv-plugins-icon-container">
                                <label className="required fs-6 fw-semibold mb-2">Código de la habitación en RR</label>
                                <input 
                                type="text" 
                                className="form-control form-control-solid" 
                                placeholder="3243" 
                                name="rr_code"
                                onChange={handleInputChange}
                                defaultValue={room.rr_code}
                                />
                                
                            </div>
                            <div className="col-md-4 fv-row fv-plugins-icon-container">
                                <label className="required fs-6 fw-semibold mb-2">Mínima capacidad</label>
                                <input 
                                type="number" 
                                className="form-control form-control-solid" 
                                placeholder="0" 
                                name="min_capacity"
                                onChange={handleInputChange}
                                defaultValue={room.min_capacity}
                                />
                            </div>
                            <div className="col-md-4 fv-row fv-plugins-icon-container">
                                <label className="required fs-6 fw-semibold mb-2">Máxima capacidad</label>
                                <input 
                                type="number" 
                                className="form-control form-control-solid" 
                                placeholder="2" 
                                name="max_capacity"
                                onChange={handleInputChange}
                                defaultValue={room.max_capacity}
                                />
                            </div>
                        
                        </div>

                    </div>
                </div>

                <div className="modal-footer flex-center">

                    <button type="reset" id="kt_modal_create_api_key_cancel" className="btn btn-light me-3">
                        Cancelar
                    </button>
                    <button type="submit" onClick={handleSave} className="btn btn-primary" data-kt-indicator={`${isLoading ? 'on' : 'off'}`}>
                        <span className="indicator-label">
                            Editar habitación
                        </span>
                        <span className="indicator-progress">
                            Guardando ... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>

                </div>

            </form>
            </Modal.Body>
        </Modal>

        </>

    );
};

export default ModalUpdateRoom;