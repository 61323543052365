import React from 'react';

const LogoutPage = () => {

    return (
        <>
        </>
    );
};

export default LogoutPage;