import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Form from "react-bootstrap/Form";
import { Button } from 'react-bootstrap';
import { postWithAuth } from '../../api/api';
import { API_URLS } from '../../api/apiConfig';
import { useNavigate } from 'react-router-dom';

const ModalEditPEXConnection = ({ onClose, pexConnection }) => {
    const [showAlert, setIshowAlert] = useState(false);
    const [alertMessage, setShowAlertMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [updateSettings, setSettings] = useState({
        building_name: pexConnection ? pexConnection.building_name : ''
    });

    const handleInputChange = (e) => {
        const { name, value, type } = e.target;

        setSettings((prevSettings) => ({
            ...prevSettings,
            [name]: type === 'checkbox' ? !prevSettings[name] : value,
        }));

        console.log(updateSettings);
    };

    const saveSettings = async () => {
        const formData = new FormData();
        formData.append('data', JSON.stringify(updateSettings));
        try {
            setIsLoading(true);
            const response = await postWithAuth(
                API_URLS.EDIT_PEX_CONNECTION,
                updateSettings
            );
            if (response.message === 'success') {
                setIsLoading(false);
                window.location.reload();
            } else if (response.message === 'error') {
                setIsLoading(false);
                setIshowAlert(true);
                setShowAlertMessage(response.detail);
            } else {
                setIsLoading(false);
                setIshowAlert(true);
                setShowAlertMessage("Ha ocurrido un error");
            }
        } catch (error) {
            setIsLoading(false);
            console.error('Error creating user admin:', error);
            setIshowAlert(true);
            setShowAlertMessage("Ha ocurrido un error");
        }
    };

    const handleSave = async (e) => {
        e.preventDefault();

        try {
            saveSettings();
        } catch (error) {
            console.error('Error creating user admin:', error);
        }
    };

    return (
        <Modal show={true} onHide={onClose} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>Edición de Building Name</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="form fv-plugins-bootstrap5 fv-plugins-framework" data-select2-id="select2-data-kt_modal_create_api_key_form">
                    <div className="modal-body py-10 px-lg-17" data-select2-id="select2-data-108-9vb6">
                        <div className="scroll-y me-n7 pe-7" id="kt_modal_create_api_key_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_create_api_key_header" data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll" data-kt-scroll-offset="300px" data-select2-id="select2-data-kt_modal_create_api_key_scroll">
                            <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-10 p-6">
                                <i className="ki-outline ki-information fs-2tx text-warning me-4"></i>
                                <div className="d-flex flex-stack flex-grow-1">
                                    <div className="fw-semibold">
                                        <h4 className="text-gray-900 fw-bold">Atención!</h4>
                                        <div className="fs-6 text-gray-700">Agregar o editar esta configuración tiene consecuencias importantes para el funcionamiento de la plataforma</div>
                                    </div>
                                </div>
                            </div>
                            {showAlert && (
                                <div className="alert alert-danger d-flex align-items-center p-5">
                                    <i className="fa-solid fa-triangle-exclamation fs-1 text-danger me-4"><span className="path1"></span><span className="path2"></span></i>
                                    <div className="d-flex flex-column">
                                        <span className="mb-1 text-danger">{alertMessage}</span>
                                    </div>
                                </div>
                            )}
                            <div className="row g-9 mb-8">
                                <div className="col-md-12 fv-row fv-plugins-icon-container">
                                    <label className="required fs-6 fw-semibold mb-2">Building Name</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-solid"
                                        placeholder="Building Name"
                                        name="building_name"
                                        onChange={handleInputChange}
                                        defaultValue={pexConnection ? pexConnection.building_name : ''}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal-footer flex-center">
                        <button type="reset" className="btn btn-light me-3">
                            Cancelar
                        </button>
                        <button type="submit" onClick={handleSave} className="btn btn-primary" data-kt-indicator={`${isLoading ? 'on' : 'off'}`}>
                            <span className="indicator-label">
                                Cambiar nombre
                            </span>
                            <span className="indicator-progress">
                                Guardando... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default ModalEditPEXConnection;
