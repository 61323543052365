import React, { useState } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import { postWithAuth } from '../../api/api';
import { API_URLS } from '../../api/apiConfig';

const ModalCleaningCreate = ({ onClose, rooms }) => {
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setShowAlertMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const date_now = new Date();
    const dateNowFormatted = date_now.toISOString().split('T')[0];

    const [updateCleaning, setUpdateCleaning] = useState({
        status: "Por hacer",
        date_cleaning: dateNowFormatted,
        is_forced: true,
        cleaning_type: "C. Textil"
    });

    const handleCleaningForce = (e) => {
        const value = e.target.value === 'true';
        setUpdateCleaning(prevCleaning => ({
            ...prevCleaning,
            is_forced: value,
        }));
        console.log(updateCleaning);
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setUpdateCleaning(prevCleaning => ({
            ...prevCleaning,
            [name]: type === 'checkbox' ? checked : value,
        }));
        console.log(updateCleaning);
    };

    const saveCleaning = async () => {
        try {
            setIsLoading(true);
            const response = await postWithAuth(API_URLS.NEW_CLEANING, updateCleaning);
            console.log("updateCleaning", updateCleaning);
            if (response.message === 'success') {
                setIsLoading(false);
                window.location.reload();
            } else {
                setIsLoading(false);
                setShowAlert(true);
                setShowAlertMessage(response.detail);
            }
        } catch (error) {
            console.error('Error creating cleaning:', error);
            setIsLoading(false);
            setShowAlert(true);
            setShowAlertMessage("Unexpected error occurred.");
        }
    };

    const handleSave = async (e) => {
        e.preventDefault();
        await saveCleaning();
    };

    return (
        <Modal show={true} onHide={onClose} size="xl" centered>
            <Modal.Header closeButton>
                <Modal.Title>Nueva limpieza</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form id="kt_modal_create_api_key_form" className="form">
                    <div className="modal-body py-5 px-lg-17">
                        <div className="scroll-y me-n7 pe-7" id="kt_modal_create_api_key_scroll">
                            <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-10 p-6">
                                <i className="ki-outline ki-information fs-2tx text-warning me-4"></i>
                                <div className="d-flex flex-stack flex-grow-1 ">
                                    <div className="fw-semibold">
                                        <h4 className="text-gray-900 fw-bold">Atención!</h4>
                                        <div className="fs-6 text-gray-700">Cambiar una limpieza puede tener consecuencias en su planificacion</div>
                                    </div>
                                </div>
                            </div>
                            {showAlert && (
                                <div className="alert alert-danger d-flex align-items-center p-5">
                                    <i className="fa-solid fa-triangle-exclamation fs-1 text-danger me-4"></i>
                                    <div className="d-flex flex-column">
                                        <span className="mb-1 text-danger">{alertMessage}</span>
                                    </div>
                                </div>
                            )}
                            <div className="d-flex flex-column mb-5 fv-row">
                                <label className="form-label">Fecha de limpieza</label>
                                <Form.Control
                                    className="d-flex align-items-center"
                                    placeholder='Selecciona día'
                                    name="date_cleaning"
                                    onChange={handleInputChange}
                                    defaultValue={updateCleaning.date_cleaning}
                                    type="date"
                                />
                            </div>
                            <div className="d-flex flex-column mb-5 fv-row">
                                <label className="form-label">Tipo de limpieza</label>
                                <select
                                    className="form-select"
                                    onChange={handleInputChange}
                                    value={updateCleaning.cleaning_type}
                                    name="cleaning_type"
                                    aria-label="Select example"
                                >
                                    <option value="L. Checkout">L. Checkout</option>
                                    <option value="C. Textil">C. Textil</option>
                                    <option value="L. Cliente">L. Cliente</option>
                                </select>
                            </div>
                            <div className="d-flex flex-column mb-5 fv-row">
                                <label className="form-label">Habitación</label>
                                <select
                                    className="form-select"
                                    onChange={handleInputChange}
                                    name="room_id"
                                    aria-label="Select example"
                                >
                                    {rooms.map(room => (
                                        <option key={room.id} value={room.id}>{room.title}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="row g-9 mb-8">
                                <div className="col-md-12 fv-row">
                                    <div className="d-flex flex-stack">
                                        <div className="me-5">
                                            <label className="fs-6 fw-semibold form-label">Fuerza la limpieza</label>
                                            <div className="fs-7 fw-semibold text-muted">Esta limpieza no se eliminará a pesar de que el algoritmo decida eliminarla</div>
                                        </div>
                                        <label className="form-check form-switch form-check-custom form-check-solid">
                                            <select
                                                onChange={handleCleaningForce}
                                                name="is_forced"
                                                className="form-select"
                                                value={updateCleaning.is_forced.toString()}
                                                aria-label="Select example"
                                            >
                                                <option value="true">Si</option>
                                                <option value="false">No</option>
                                            </select>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer flex-center">
                        <Button variant="light" onClick={onClose}>
                            Cancelar
                        </Button>
                        <Button variant="primary" onClick={handleSave} disabled={isLoading}>
                            {isLoading ? (
                                <>
                                    Guardando... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </>
                            ) : (
                                "Crear Limpieza"
                            )}
                        </Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default ModalCleaningCreate;
