/**
 * Scheduler config file
 */
import { DateHelper, StringHelper } from '@bryntum/scheduler';

const schedulerConfig = {
    resourceImagePath : 'users/',
    columns           : [
        {
            type  : 'resourceInfo',
            text  : 'Habitaciones',
            width : 170
        }
    ],
    filterBarFeature  : true,
    stripeFeature     : true,
    timeRangesFeature : true,
    barMargin : 5,
    rowHeight : 55,
    eventDrag: false,
    eventResize: false,
    viewPreset : 'dayAndWeek',

    // Specialized event bar template with header and footer
    eventRenderer({ eventRecord, resourceRecord, renderData }) {
        renderData.style = `background-color:${eventRecord.eventColor}`;

        return `
            <div class="">${StringHelper.encodeHtml(eventRecord.name) || ''}</div>
        `;
    }
};

const findConfig = {
    placeholder          : 'Find tasks by name',
    keyStrokeChangeDelay : 80,
    clearable            : true,
    width                : '12.5em',
    triggers             : {
        filter : {
            align : 'start',
            cls   : 'b-fa b-fa-filter'
        }
    }
};

const highlightConfig = {
    placeholder          : 'Highlight tasks',
    keyStrokeChangeDelay : 80,
    clearable            : true,
    width                : '12.5em',
    triggers             : {
        filter : {
            align : 'start',
            cls   : 'b-fa b-fa-search'
        }
    }
};

export { schedulerConfig, findConfig, highlightConfig };
