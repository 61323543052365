import React, { useEffect, useState } from 'react';
import { getWithAuth } from '../../api/api';
import { API_URLS } from '../../api/apiConfig';
import { useSettings } from '../../settings/settingsContext';
import YesSettingsPage from './yes_settings';
import NoSettingsPage from './no_settings';

const SettingsPage = () => {
    const settingsContext = useSettings();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingMapping, setIsLoadingMapping] = useState(false);
    const [isLoadingPEX, setIsLoadingPEX] = useState(false);
    const [settings, setSettings] = useState(null);
    const [pexConnection, setPexConnection] = useState(null);
    const [pexMappings, setPexMapping] = useState([]);
    const [hasSettings, setHasSettings] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                console.log("Get Categories");
                const data = await getWithAuth(API_URLS.GET_RR_CONNECION);
                if (data.message === 'success') {
                    setHasSettings(true);
                    setSettings(data.data);
                } else if (data.message === 'error') {
                    setHasSettings(false);
                }
            } catch (error) {
                settingsContext.setMessage("Ha ocurrido un error conectando con el servidor");
                settingsContext.setAlertType("danger");
                settingsContext.openAlertHeader();
                console.error('Error getting categories:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoadingPEX(true);
            try {
                console.log("Get PEX");
                const data = await getWithAuth(API_URLS.GET_PEX_CONNECION);
                if (data.message === 'success') {
                    setPexConnection(data.data);
                } else if (data.message === 'error') {
                    // Handle error if needed
                }
            } catch (error) {
                settingsContext.setMessage("Ha ocurrido un error conectando con el servidor");
                settingsContext.setAlertType("danger");
                settingsContext.openAlertHeader();
                console.error('Error getting PEX connection:', error);
            } finally {
                setIsLoadingPEX(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchDataMappings = async () => {
            setIsLoadingMapping(true);
            try {
                console.log("Get PEX Mappings");
                const data = await getWithAuth(API_URLS.GET_PEX_MAPPINGS);
                if (data.message === 'success') {
                    setPexMapping(data.data);
                }
            } catch (error) {
                settingsContext.setMessage("Ha ocurrido un error conectando con el servidor");
                settingsContext.setAlertType("danger");
                settingsContext.openAlertHeader();
                console.error('Error getting PEX mappings:', error);
            } finally {
                setIsLoadingMapping(false);
            }
        };

        fetchDataMappings();
    }, []);

    return (
        <div className="d-flex flex-column flex-column-fluid">
            <div id="kt_app_toolbar" className="app-toolbar">
                <div id="kt_app_toolbar_container" className="app-container container-fluid d-flex flex-stack">
                    <div className="page-title d-flex flex-column justify-content-center me-3 mb-6 mb-lg-0">
                        <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center me-3 mb-3 my-0">
                            Ajustes y conectividad
                        </h1>
                    </div>
                </div>
            </div>
            <div id="kt_app_content" className="app-content flex-column-fluid">
                <div id="kt_app_content_container" className="app-container container-fluid mt-5">
                    {(isLoading || isLoadingMapping || isLoadingPEX) ? (
                        <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                            <div className="spinner-grow text-color-amarillo-livensa" style={{ width: "5rem", height: "5em" }} role="status">
                            </div>
                        </div>
                    ) : (
                        hasSettings ? (
                            <YesSettingsPage
                                settings={settings}
                                pexConnection={pexConnection}
                                pexMappings={pexMappings}
                            />
                        ) : (
                            <NoSettingsPage />
                        )
                    )}
                </div>
            </div>
        </div>
    );
};

export default SettingsPage;
