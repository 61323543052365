import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '../components/sidebar';
import Header from '../components/header';
import Footer from '../components/footer';
import { useAuth } from './context/authcontext';
import { API_URLS } from '../api/apiConfig';
import { getWithAuth } from '../api/api';
import Cookies from 'universal-cookie';


const MainLayout = () => {

    const { authData, setAuthData } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const cookies = new Cookies();

    useEffect(() => {
        const checkUserToken = async () => {
            try {
                const session_token = cookies.get('session_token');
                if (session_token) {
                    // Aquí puedes realizar una solicitud al backend para verificar si el token es válido
                    setIsLoading(true);
                    const rsp = await getWithAuth(API_URLS.GET_USER_SESSION);
                    const userData = rsp.data;
                    setIsLoading(false);
                    setAuthData({
                        isAuthenticated: true,
                        session_token,
                        user_data: userData,
                    });
                } else {
                    // Si no hay un token de sesión, redirigir al inicio de sesión
                    setIsLoading(false);
                    navigate('/login');
                }
            } catch (error) {
                console.error('Error en la verificación del token:', error);
                // Manejar el error adecuadamente, por ejemplo, redirigir al inicio de sesión
                navigate('/login');
            }
        };

        checkUserToken();

        return () => {
            // Limpieza de efectos secundarios si es necesario
        };
    }, []);

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    return (
        <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
            <div className="app-page flex-column flex-column-fluid" id="kt_app_page">
                {!isLoading && authData.user_data && (
                    <>
                        <Header toggleDrawer={toggleDrawer} userData={authData.user_data} />
                        <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                            <Sidebar isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />
                            <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                                <Outlet />
                                <Footer />
                            </div>
                        </div>
                    </>
                )}
                {isLoading && (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                        <div className="spinner-grow text-color-amarillo-livensa" style={{ width: "5rem", height: "5em" }} role="status"></div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MainLayout;
