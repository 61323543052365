import React, { useContext, useState, useRef, useEffect } from 'react';
import { MainMenuList } from '../base/siteMaps';
import { Link, NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'

const Sidebar = ({ isDrawerOpen, setIsDrawerOpen }) => {

    const location = useLocation();
    const currentPath = location.pathname;
    const sidebarRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                setIsDrawerOpen(false); // Cierra el drawer si se hace clic fuera del menú
            }
        };

        // Agrega un controlador de eventos de clic al documento para detectar clics fuera del menú
        document.addEventListener("mousedown", handleClickOutside);

        // Limpia el controlador de eventos cuando el componente se desmonta para evitar fugas de memoria
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <>
            <div ref={sidebarRef} id="kt_app_sidebar" className={`kt_app_sidebar_style app-sidebar flex-column drawer drawer-start ${isDrawerOpen ? 'drawer-on' : ''}`} data-kt-drawer="true" data-kt-drawer-name="app-sidebar" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="100px" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle">

                <div className="app-sidebar-logo d-none d-lg-flex flex-center pt-8 mb-3" id="kt_app_sidebar_logo">

                    <a href="index.html">
                        <img alt="Logo" src="/media/livensa/Studios-livensaliving.png" className="h-20px" />
                    </a>
                </div>
                <div className="app-sidebar-menu d-flex flex-center align-items-start overflow-hidden flex-column-fluid">

                    <div id="kt_app_sidebar_menu_wrapper" className="kt_app_sidebar_menu_wrapper_style app-sidebar-wrapper d-flex hover-scroll-overlay-y scroll-ms my-5" data-kt-scroll="true" data-kt-scroll-activate="true" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer" data-kt-scroll-wrappers="#kt_app_sidebar_menu, #kt_app_sidebar" data-kt-scroll-offset="5px">

                        <div className="menu menu-column menu-rounded menu-active-bg menu-state-primary my-auto" id="#kt_app_sidebar_menu" data-kt-menu="true" data-kt-menu-expand="false">

                            {MainMenuList.children.map((route, index) => (
                                <a key={index} href={route.to}>
                                    {currentPath.includes(route.to) ? (
                                        <div className="menu-item py-2">
                                            <span className="menu-link menu-center ">
                                                <span className="menu-icon menu-icon-active me-0">
                                                    <i className={`fa-solid fa-${route.icon} fs-2x text-color-azul-livensa`}></i>
                                                </span>
                                            </span>
                                        </div>
                                    ) : (
                                        <div data-kt-menu-placement="right-start"
                                            className="menu-item py-2">
                                            <span className="menu-link menu-center">
                                                <span className="menu-icon me-0">
                                                    <i className={`fa-solid fa-${route.icon} fs-2x text-color-blanco-livensa`}></i>
                                                </span>
                                            </span>
                                        </div>
                                    )}
                                </a>
                            ))}

                        </div>

                    </div>

                </div>


                <div className="app-sidebar-footer d-flex flex-center flex-column-auto pt-6 mb-7" id="kt_app_sidebar_footer">

                    <div className="mb-0">
                        <button type="button" className="btn btm-sm btn-custom btn-icon" data-kt-menu-trigger="click" data-kt-menu-overflow="true" data-kt-menu-placement="top-start" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-dismiss="click" aria-label="Quick actions" data-bs-original-title="Quick actions" data-kt-initialized="1">
                            <i className="fonticon-notification fs-1"></i>
                        </button>

                        <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px" data-kt-menu="true">

                            <div className="menu-item px-3">
                                <div className="menu-content fs-6 text-gray-900 fw-bold px-3 py-4">Quick Actions</div>
                            </div>


                            <div className="separator mb-3 opacity-75"></div>


                            <div className="menu-item px-3">
                                <a href="#" className="menu-link px-3">New Ticket</a>
                            </div>


                            <div className="menu-item px-3">
                                <a href="#" className="menu-link px-3">New Customer</a>
                            </div>


                            <div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">

                                <a href="#" className="menu-link px-3">
                                    <span className="menu-title">New Group</span>
                                    <span className="menu-arrow"></span>
                                </a>


                                <div className="menu-sub menu-sub-dropdown w-175px py-4">

                                    <div className="menu-item px-3">
                                        <a href="#" className="menu-link px-3">Admin Group</a>
                                    </div>


                                    <div className="menu-item px-3">
                                        <a href="#" className="menu-link px-3">Staff Group</a>
                                    </div>


                                    <div className="menu-item px-3">
                                        <a href="#" className="menu-link px-3">Member Group</a>
                                    </div>

                                </div>

                            </div>


                            <div className="menu-item px-3">
                                <a href="#" className="menu-link px-3">New Contact</a>
                            </div>


                            <div className="separator mt-3 opacity-75"></div>


                            <div className="menu-item px-3">
                                <div className="menu-content px-3 py-3">
                                    <a className="btn btn-primary btn-sm px-4" href="#">Generate Reports</a>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>

            </div>
            <Tooltip className="tooltip-single" anchorSelect={`#planification`} clickable>
                <div className="text-black" style={{ color: "white" }}>Planificación</div>
            </Tooltip>
        </>
    );

};

export default Sidebar;