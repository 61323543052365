import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { API_URLS } from '../../api/apiConfig';
import { getWithAuth } from '../../api/api';


const ModalGapInfo = ({ onClose, inventory_gap_id }) => {

    const [showAlert, setIshowAlert] = useState(false);
    const [alertMessage, setShowAlertMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [gap, setGap] = useState({});


    const getGAP = async (e) => {
        try {
            setIsLoading(true)
            const response = await getWithAuth(
                API_URLS.GET_INVENTORY_GAP(inventory_gap_id),
            );
            console.log(response.data)
            if (response.message === 'success') {
                setIsLoading(false)
                setGap(response.data)
            } else if (response.message === 'error') {
                setIsLoading(false)
                setShowAlertMessage(response.detail);
                setIshowAlert(true);
            } else {
                //setAlertType('danger');
                //setMessage("Error inesperado");
                //setShowAlertHeader(true);
            }
        } catch (error) {
            console.error('Error creating user admin:', error);
        }

    };

    useEffect(() => {
        getGAP();
    }, []);

    return (
        <>
            <Modal show={true} onHide={onClose} size="s" centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Información de Gap</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {isLoading ? (
                            <div className="d-flex justify-content-center align-items-center" style={{ height: '10vh' }}>
                                <div className="spinner-grow text-color-amarillo-livensa" style={{ width: "5rem", height: "5em" }} role="status">
                                </div>
                            </div>
                        ) : (
            <div class="card card-flush h-lg-100">  
            {showAlert && (
                                    <div className="alert alert-danger d-flex align-items-center p-5">
                                        <i className="fa-solid fa-triangle-exclamation fs-1 text-danger me-4"><span className="path1"></span><span className="path2"></span></i>
                                        <div className="d-flex flex-column">
                                            <span className="mb-1 text-danger">{alertMessage}</span>
                                        </div>
                                    </div>
                                    )}
            <div class="card-header pt-5">
                <h3 class="card-title align-items-start flex-column">            
                    <span class="card-label fw-bold text-gray-900">Gap: {(gap.pex_avaiability - gap.roomraccoon_bookings)-gap.roomraccoon_avaiability} </span>
                    <span class="text-gray-500 mt-1 fw-semibold fs-6">Información del GAP en los canales</span>
                    <span class="text-gray-500 mt-1 fw-semibold fs-6">(Disponibilidad PEX - Reservas RR) - Disponibilidad RR</span>
                </h3>
            </div>

    <div class="card-body pt-5">                 
            <div class="d-flex flex-stack">
                <div class="text-gray-700 fw-semibold fs-6 me-2">Reservas en PEX</div>                   

                <div class="d-flex align-items-senter">
                                            <i class="ki-outline ki-arrow-down-right fs-2 text-danger me-2"></i>  
                                        
                    <span class="text-gray-900 fw-bolder fs-6">{gap.pex_bookings}</span> 
                    
                                    </div>  
            </div>

                <div class="separator separator-dashed my-3"></div>
             
            <div class="d-flex flex-stack">
                <div class="text-gray-700 fw-semibold fs-6 me-2">Reservas RR</div>                   

                <div class="d-flex align-items-senter">
                                            <i class="ki-outline ki-arrow-down-right fs-2 text-danger me-2"></i>                 
                                        
                    <span class="text-gray-900 fw-bolder fs-6">{gap.roomraccoon_bookings}</span> 
                    
                                                             
                                    </div>  
            </div>

                <div class="separator separator-dashed my-3"></div>
             
            <div class="d-flex flex-stack">
                <div class="text-gray-700 fw-semibold fs-6 me-2">Disponibilidad RR</div>                   

                <div class="d-flex align-items-senter">
                                            <i class="ki-outline ki-arrow-up-right fs-2 text-success me-2"></i>  
                                        
                    <span class="text-gray-900 fw-bolder fs-6">{gap.roomraccoon_avaiability}</span> 
                    
                                                             
                                    </div>  
            </div>

            
            <div class="separator separator-dashed my-3"></div>
             
            <div class="d-flex flex-stack">
                <div class="text-gray-700 fw-semibold fs-6 me-2">Disponibilidad PEX</div>                   

                <div class="d-flex align-items-senter">
                                            <i class="ki-outline ki-arrow-up-right fs-2 text-success me-2"></i>  
                                        
                    <span class="text-gray-900 fw-bolder fs-6">{gap.pex_avaiability}</span> 
                    
                                                             
                                    </div>  
            </div>
             
            
    </div>
</div>
            )}
            </Modal.Body>
            </Modal>

        </>

    );
};

export default ModalGapInfo;