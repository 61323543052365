import React, { useContext, useEffect, useState } from 'react';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../base/context/authcontext';
import Cookies from 'universal-cookie';

const ProtectedRoute = ({ children }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { authData, setAuthData } = useContext(AuthContext);
    const cookies = new Cookies();


    return children;
};

export default ProtectedRoute;
