import React, { useState } from 'react';
import Form from "react-bootstrap/Form";
import { Button, Modal } from 'react-bootstrap';
import moment from 'moment-timezone';
import { postWithAuth } from '../../api/api';
import { API_URLS } from '../../api/apiConfig';

// Función para ajustar la fecha a la zona horaria de Madrid
const adjustDateToMadrid = (date) => {
    const madridDate = moment(date).tz('Europe/Madrid').format('YYYY-MM-DD');
    return madridDate;
};

const ModalCleaningCreateFromCalendar = ({ onClose, date, roomTitle, roomId }) => {
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [updateCleaning, setCleaning] = useState({
        status: "Por hacer",
        date_cleaning: adjustDateToMadrid(date), // Ajusta la fecha al inicializar el estado
        room_id: roomId,
        is_forced: true,
        cleaning_type: "C. Textil"
    });

    const handleCleaningForce = (e) => {
        const value = e.target.value === 'true';
        setCleaning((prevCleaning) => ({
            ...prevCleaning,
            is_forced: value,
        }));
    };

    const handleInputChange = (e) => {
        const { name, value, type } = e.target;
        setCleaning((prevCleaning) => ({
            ...prevCleaning,
            [name]: type === 'checkbox' ? !prevCleaning[name] : value,
        }));
    };

    const saveCleaning = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const response = await postWithAuth(API_URLS.NEW_CLEANING, updateCleaning);
            if (response.message === 'success') {
                setIsLoading(false);
                window.location.reload();
            } else {
                setIsLoading(false);
                setShowAlert(true);
                setAlertMessage(response.detail);
            }
        } catch (error) {
            setIsLoading(false);
            console.error('Error creating cleaning:', error);
        }
    };

    return (
        <Modal show={true} onHide={onClose} size="xl" centered>
            <Modal.Header closeButton>
                <Modal.Title>Nueva limpieza</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={saveCleaning} className="form fv-plugins-bootstrap5 fv-plugins-framework">
                    <div className="modal-body py-5 px-lg-17">
                        <h2 className="mb-5">{roomTitle}</h2>
                        <div className="scroll-y me-n7 pe-7" id="kt_modal_create_api_key_scroll">
                            <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-10 p-6">
                                <i className="ki-outline ki-information fs-2tx text-warning me-4"></i>
                                <div className="d-flex flex-stack flex-grow-1">
                                    <div className="fw-semibold">
                                        <h4 className="text-gray-900 fw-bold">Atención!</h4>
                                        <div className="fs-6 text-gray-700">Cambiar una limpieza puede tener consecuencias en su planificación</div>
                                    </div>
                                </div>
                            </div>
                            {showAlert && (
                                <div className="alert alert-danger d-flex align-items-center p-5">
                                    <i className="fa-solid fa-triangle-exclamation fs-1 text-danger me-4"></i>
                                    <div className="d-flex flex-column">
                                        <span className="mb-1 text-danger">{alertMessage}</span>
                                    </div>
                                </div>
                            )}
                            <div className="d-flex flex-column mb-5 fv-row fv-plugins-icon-container">
                                <label className="form-label">Fecha de limpieza</label>
                                <Form.Control
                                    className="d-flex align-items-center"
                                    placeholder='Selecciona día'
                                    name="date_cleaning"
                                    onChange={handleInputChange}
                                    defaultValue={updateCleaning.date_cleaning}
                                    type="date"
                                />
                            </div>
                            <div className="d-flex flex-column mb-5 fv-row fv-plugins-icon-container">
                                <label className="form-label">Tipo de limpieza</label>
                                <select className="form-select" onChange={handleInputChange} defaultValue="Repaso" name="cleaning_type">
                                <option value="L. Checkout">L. Checkout</option>
                                <option value="C. Textil">C. Textil</option>
                                <option value="L. Cliente">L. Cliente</option>
                                </select>
                            </div>
                            <div className="row g-9 mb-8">
                                <div className="col-md-12 fv-row">
                                    <div className="d-flex flex-stack">
                                        <div className="me-5">
                                            <label className="fs-6 fw-semibold form-label">Fuerza la limpieza</label>
                                            <div className="fs-7 fw-semibold text-muted">Esta limpieza no se eliminará a pesar de que el algoritmo decida eliminarla</div>
                                        </div>
                                        <select onChange={handleCleaningForce} name="is_forced" className="form-select">
                                            <option value={true}>Si</option>
                                            <option value={false}>No</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer flex-center">
                        <button type="reset" onClick={onClose} className="btn btn-light me-3">
                            Cancelar
                        </button>
                        <button type="submit" className="btn btn-primary" data-kt-indicator={`${isLoading ? 'on' : 'off'}`}>
                            <span className="indicator-label">
                                Crear Limpieza
                            </span>
                            <span className="indicator-progress">
                                Guardando... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default ModalCleaningCreateFromCalendar;
