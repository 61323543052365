import React, { createContext, useContext, useState } from 'react';

const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {

    const [message, setMessage] = useState('Esto un mensaje de prueba');
    const [alertType, setAlertType] = useState('success');
    const [settings, setSettings] = useState(null);
    const [pexMapping, setPexMapping] = useState(null);
    const [pexConnection, setPexConnection] = useState(null);

    //Gestion del Alert del Header
    const [showAlertHeader, setShowAlertHeader] = useState(false);
    const openAlertHeader = () => {
        setShowAlertHeader(true);
    };
    const closeAlertHeader = () => {
        setShowAlertHeader(false);
    };


    const [showNewSettingsModal, setShowNewSettingsModal] = useState(false);
    const openNewSettingsModal = () => {
        setShowNewSettingsModal(true);
    };

    const closeNewSettingsModal = () => {
        setShowNewSettingsModal(false);
    };

    const [showNewSettingsRRCodeModal, setShowNewSettingsRRCodeModal] = useState(false);
    const openNewSettingsRRCodeModal = (settings) => {
        setSettings(settings)
        setShowNewSettingsRRCodeModal(true);
    };

    const closeNewSettingsRRCodeModal = () => {
        setShowNewSettingsRRCodeModal(false);
    };

    const [showNewSettingsRRCredentialsModal, setShowNewSettingsRRCredentialsModal] = useState(false);
    const openNewSettingsRRCredentialsModal = (settings) => {
        setSettings(settings)
        setShowNewSettingsRRCredentialsModal(true);
    };

    const closeNewSettingsRRCredentialsModal = () => {
        setShowNewSettingsRRCredentialsModal(false);
    };

    const [apiSuccess, setApiSuccessSuccess] = useState(false);
    const changeApiSuccess = () => {
        setApiSuccessSuccess(!apiSuccess);
    };

    const [showNewPexMappingModal, setShowNewPexMappingModal] = useState(false);
    const openNewPexMappingModal = () => {
        setShowNewPexMappingModal(true);
    };

    const closeNewPexMappingModal = () => {
        setShowNewPexMappingModal(false);
    };

    const [showEditPexMappingModal, setShowEditPexMappingModal] = useState(false);
    const openEditPexMappingModal = (pexMapping) => {
        setPexMapping(pexMapping)
        setShowEditPexMappingModal(true);
    };

    const closeEditPexMappingModal = () => {
        setShowEditPexMappingModal(false);
    };

    const [showEditPEXConnectionModal, setShowEditPEXConnectionModal] = useState(false);
    const openEditPEXConnectionModal = (pexConnection) => {
        setPexConnection(pexConnection)
        setShowEditPEXConnectionModal(true);
    };

    const closeEditPEXConnectionModal = () => {
        setShowNewSettingsRRCredentialsModal(false);
    };

    return (
        <SettingsContext.Provider value={{
            apiSuccess,
            changeApiSuccess,
            alertType,
            setAlertType,
            setMessage,
            message,
            settings,
            pexMapping,
            pexConnection,
            openAlertHeader,
            closeAlertHeader,
            showAlertHeader,
            setShowNewSettingsModal,
            showNewSettingsModal,
            openNewSettingsModal,
            closeNewSettingsModal,
            setShowNewSettingsRRCodeModal,
            showNewSettingsRRCodeModal,
            openNewSettingsRRCodeModal,
            closeNewSettingsRRCodeModal,
            setShowNewSettingsRRCredentialsModal,
            showNewSettingsRRCredentialsModal,
            openNewSettingsRRCredentialsModal,
            closeNewSettingsRRCredentialsModal,
            openEditPexMappingModal,
            closeEditPexMappingModal,
            openNewPexMappingModal,
            closeNewPexMappingModal,
            showNewPexMappingModal,
            showEditPexMappingModal,
            setPexMapping,
            openEditPEXConnectionModal,
            closeEditPEXConnectionModal,
            showEditPEXConnectionModal
        }}>
            {children}
        </SettingsContext.Provider>
    );
};

export const useSettings = () => {
    return useContext(SettingsContext);
};
