import React, { createContext, useContext, useState } from 'react';

const PlanificationContext = createContext();

export const PlanificationProvider = ({ children }) => {

    const [selectedCleaning, setSelectedCleaning] = useState(null);
    const today = new Date();
    //Gestion del contenido de los Alert
    const [message, setMessage] = useState('Esto un mensaje de prueba');
    const [alertType, setAlertType] = useState('success');
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedBooking, setSelectedBooking] = useState(null);
    const [selectedRoomTitle, setSelectedRoomTitle] = useState(null);
    const [selectedRoomId, setSelectedRoomId] = useState(null);
    const [dateForCalendar, setDateForCalendar] = useState(today);
    const [dateTimelineActive, setDateTimelineActive] = useState(today.toDateString());

    //Gestion del Alert del Header
    const [showAlertHeader, setShowAlertHeader] = useState(false);
    const openAlertHeader = () => {
        setShowAlertHeader(true);
    };
    const closeAlertHeader = () => {
        setShowAlertHeader(false);
    };


    const [showNewCleaningModal, setShowNewCleaningModal] = useState(false);
    const openNewCleaningModal = () => {
        setShowNewCleaningModal(true);
    };

    const closeNewCleaningModal = () => {
        setShowNewCleaningModal(false);
    };


    const [showNewBookingModal, setShowNewBookingModal] = useState(false);
    const openNewBookingModal = () => {
        setShowNewBookingModal(true);
    };

    const closeNewBookingModal = () => {
        setShowNewBookingModal(false);
    };

    const [showNewCleaningModalFromCalendar, setShowNewCleaningModalFromCalendar] = useState(false);
    const openNewCleaningModalFromCalendar = (date, roomTitle, roomId) => {
        setSelectedDate(date);
        setSelectedRoomTitle(roomTitle);
        setSelectedRoomId(roomId);
        setShowNewCleaningModalFromCalendar(true);
    };

    const closeNewCleaningModalFromCalendar = () => {
        setShowNewCleaningModalFromCalendar(false);
    };

    const [showUpdateCleaningModal, setShowUpdateCleaningModal] = useState(false);
    const openUpdateCleaningModal = (cleaning_id) => {
        setSelectedCleaning(cleaning_id);
        setShowUpdateCleaningModal(true);
    };
    const closeUpdateCleaningModal = () => {
        setShowUpdateCleaningModal(false);
    };

    const [showUpdateBookingModal, setShowUpdateBookingModal] = useState(false);
    const openUpdateBookingModal = (booking_id) => {
        setSelectedBooking(booking_id);
        setShowUpdateBookingModal(true);
    };
    const closeUpdateBookingModal = () => {
        setShowUpdateBookingModal(false);
    };

    const [apiSuccess, setApiSuccessSuccess] = useState(false);
    const changeApiSuccess = () => {
        setApiSuccessSuccess(!apiSuccess);
    };

    const [showExportCleaningModal, setShowExportCleaningModal] = useState(false);
    const openExportCleaningModal = () => {
        setShowExportCleaningModal(true);
    };

    const closeExportCleaningModal = () => {
        setShowExportCleaningModal(false);
    };

    return (
        <PlanificationContext.Provider value={{
            apiSuccess,
            changeApiSuccess,
            alertType,
            setAlertType,
            setMessage,
            message,
            selectedDate,
            openAlertHeader,
            closeAlertHeader,
            showAlertHeader,
            selectedCleaning,
            selectedBooking,
            setSelectedBooking,
            selectedRoomTitle,
            setSelectedRoomTitle,
            selectedRoomId,
            setSelectedRoomId,
            setSelectedCleaning,
            setShowNewCleaningModalFromCalendar,
            showNewCleaningModalFromCalendar,
            openNewCleaningModalFromCalendar,
            closeNewCleaningModalFromCalendar,
            setShowNewCleaningModal,
            showNewCleaningModal,
            openNewCleaningModal,
            closeNewCleaningModal,
            setShowNewBookingModal,
            showNewBookingModal,
            openNewBookingModal,
            closeNewBookingModal,
            showUpdateCleaningModal,
            openUpdateCleaningModal,
            closeUpdateCleaningModal,
            showUpdateBookingModal,
            openUpdateBookingModal,
            closeUpdateBookingModal,
            dateForCalendar,
            setDateForCalendar,
            dateTimelineActive,
            setDateTimelineActive,
            openExportCleaningModal,
            closeExportCleaningModal,
            showExportCleaningModal,
            setShowExportCleaningModal
        }}>
            {children}
        </PlanificationContext.Provider>
    );
};

export const usePlanification = () => {
    return useContext(PlanificationContext);
};
