import React from 'react';
import { useState, useEffect } from 'react';
import Form from "react-bootstrap/Form";
import { Button, Modal } from 'react-bootstrap';
import { getWithAuth, putWithAuth } from '../../api/api';
import { API_URLS } from '../../api/apiConfig';
import ModalDeleteCleaning from './modalDeleteCleaning';


const ModalCleaningUpdate = ({ onClose, cleaning_id }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
    const [alertMessage, setShowAlertMessage] = useState("");
    const [showAlert, setIshowAlert] = useState(false);

    const [updateCleaning, setCleaning] = useState({});

    const handleCleaningForce = (e) => {
        const value = e.target.value === 'true';
    
        setCleaning((prevCleaning) => ({
            ...prevCleaning,
            is_forced: value,
        }));
        console.log(updateCleaning)
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;

        setCleaning((prevCleaning) => {
            const updateCleaning = {
                ...prevCleaning,
                [name]: type === 'checkbox' ? !prevCleaning[name] : value,
            };
            return updateCleaning;
        });

        console.log(updateCleaning)
    };

    const getCleaning = async (e) => {
        try {
            setIsLoading(true)
            const response = await getWithAuth(
                API_URLS.GET_CLEANING(cleaning_id),
            );
            console.log(response.data)
            if (response.message === 'success') {
                setIsLoading(false)
                setCleaning(response.data)
            } else if (response.message === 'error') {
                setIsLoading(false)
                setIshowAlert(true);
                setShowAlertMessage(response.detail)
            } else {
                //setAlertType('danger');
                //setMessage("Error inesperado");
                //setShowAlertHeader(true);
            }
        } catch (error) {
            console.error('Error creating user admin:', error);
        }
    };

    const editCleaning = async (e) => {

        const formData = new FormData();
        formData.append('data', JSON.stringify(updateCleaning));
        try {
            setIsLoadingUpdate(true)
            const response = await putWithAuth(
                API_URLS.EDIT_CLEANING(cleaning_id),
                updateCleaning
            );
            console.log("FormData", formData)
            if (response.message === 'success') {
                setIsLoadingUpdate(false)
                window.location.reload();
            } else if (response.message === 'error') {
                setIsLoadingUpdate(false)
                setIshowAlert(true);
                setShowAlertMessage(response.detail);
            } else {
                setIsLoadingUpdate(false)
                setIshowAlert(true);
                setShowAlertMessage("Ha ocurrido un error");
            }
        } catch (error) {
            console.error('Error creating user admin:', error);
        }

    };

    const handleSave = async (e) => {
        e.preventDefault();

        try {
            editCleaning();
        } catch (error) {
            console.error('Error creating user admin:', error);
        }
    };

    useEffect(() => {
        getCleaning();
    }, []);

    const handleClickDelete = () => {
        ModalDeleteCleaning.handleDelete(cleaning_id);
    };

    return (
      
        <>
            <Modal show={true} onHide={onClose} size="xl" centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Edición de limpieza (Reserva {updateCleaning.booking_external_id})</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form id="kt_modal_create_api_key_form" className="form fv-plugins-bootstrap5 fv-plugins-framework" action="#" data-select2-id="select2-data-kt_modal_create_api_key_form">
                {isLoading && Object.keys(updateCleaning).length === 0 ? (
                            <div className="d-flex justify-content-center align-items-center" style={{ height: '10vh' }}>
                                <div className="spinner-grow text-color-amarillo-livensa" style={{ width: "5rem", height: "5em" }} role="status">
                                </div>
                            </div>
                        ) : (
                            <>
                            <div className="modal-body py-5 px-lg-17" data-select2-id="select2-data-108-9vb6">
                                <div className="scroll-y me-n7 pe-7" id="kt_modal_create_api_key_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_create_api_key_header" data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll" data-kt-scroll-offset="300px" data-select2-id="select2-data-kt_modal_create_api_key_scroll">
                                    <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-10 p-6">
                                        <i className="ki-outline ki-information fs-2tx text-warning me-4"></i>
                                        <div className="d-flex flex-stack flex-grow-1 ">
                                            <div className=" fw-semibold">
                                                <h4 className="text-gray-900 fw-bold">Atención!</h4>

                                                <div className="fs-6 text-gray-700 ">Cambiar una limpieza puede tener consecuencias en su planificacion</div>
                                            </div>
                                        </div>
                                    </div>
                                    {showAlert && (
                                    <div className="alert alert-danger d-flex align-items-center p-5">
                                        <i className="fa-solid fa-triangle-exclamation fs-1 text-danger me-4"><span className="path1"></span><span className="path2"></span></i>
                                        <div className="d-flex flex-column">
                                            <span className="mb-1 text-danger">{alertMessage}</span>
                                        </div>
                                    </div>
                                    )}
                                    <div className="d-flex flex-column mb-5 fv-row fv-plugins-icon-container">
                                        <label for="exampleFormControlInput1" className="form-label">Fecha de limpieza</label>
                                        <div>
                                            <Form.Control className="d-flex align-items-center" placeholder='Selecciona día' name="date_cleaning" onChange={handleInputChange} defaultValue={updateCleaning.date_cleaning} type="date" />
                                        </div>
                                    </div>

                                    <div className="d-flex flex-column mb-5 fv-row fv-plugins-icon-container">
                                        <label for="exampleFormControlInput1" className="form-label">Tipo de limpieza</label>

                                        <select className="form-select" onChange={handleInputChange} defaultValue={updateCleaning.cleaning_type} name="cleaning_type" aria-label="Select example">
                                            <option value="L. Checkout">L. Checkout</option>
                                            <option value="C. Textil">C. Textil</option>
                                            <option value="L. Cliente">L. Cliente</option>
                                        </select>
                                    </div>

                                    <div className="row g-9 mb-8">
                                        <div className="col-md-12 fv-row">
                                            <div className="d-flex flex-stack">
                                                <div className="me-5">
                                                    <label className="fs-6 fw-semibold form-label">Fuerza la limpieza</label>
                                                    <div className="fs-7 fw-semibold text-muted">Esta limpieza no se eliminará a pesar de que el algoritmo decida eliminarla</div>
                                                </div>
                                                <label className="form-check form-switch form-check-custom form-check-solid">
                                                    <select  onChange={handleCleaningForce} name="is_forced" defaultValue={updateCleaning.is_forced} className="form-select" aria-label="Select example">
                                                        <option value={true}>Si</option>
                                                        <option value={false}>No</option>
                                                    </select>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="modal-footer flex-center">

                                <button type="reset" onClick={onClose} className="btn btn-light me-3">
                                    Cancelar
                                </button>

                                <button type="submit" onClick={handleSave} className="btn btn-primary" data-kt-indicator={`${isLoadingUpdate ? 'on' : 'off'}`}>
                                    <span className="indicator-label">
                                        Editar Limpieza
                                    </span>
                                    <span className="indicator-progress">
                                       Editando limpieza <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>

                                <button type="reset" onClick={handleClickDelete} className="btn btn-light me-3">
                                    Eliminar limpieza
                                </button>


                            </div>
                    </>
                        )}
                </form>
            </Modal.Body>
            
            </Modal>

        </>

    );
};

export default ModalCleaningUpdate;