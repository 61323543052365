import React from 'react';
import Swal from 'sweetalert2';
import { getWithAuth } from '../../api/api';
import { API_URLS } from '../../api/apiConfig';

class ModalChangeStatusCleaning extends React.Component {
    static handleChangeStatus = async (cleaning_id) => {
        const changeStatusApi = async (cleaning_id) => { // Definir deleteApi aquí como una función estática interna
            try {
                await getWithAuth(API_URLS.CHANGE_STATUS_CLEANING(cleaning_id));
            } catch (error) {
                console.error('Error changing status:', error);
                throw error;
            }
        };

        const result = await Swal.fire({
            title: '¡Cuidado!',
            text: 'Cambiar el estado de una limpieza puede afectar al panorama general de limpiezas',
            icon: 'info',
            confirmButtonText: `
                Cambiar estado
            `,
            customClass: {
                confirmButton: 'btn btn-sm btn-primary',
              },
            showCancelButton: false,
        });

        if (result.isConfirmed) {
            try {
                Swal.showLoading();
                await changeStatusApi(cleaning_id); // Utilizar la función deleteApi aquí
                window.location.reload();
            } catch (error) {
                console.error('Error deleting rule:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cambiar de estado',
                    text: 'Hubo un problema al intentar cambiar el estado de la regla de limpieza. Por favor, inténtalo de nuevo más tarde.',
                });
            }
        }
    };

    render() {
        return null;
    }
}

export default ModalChangeStatusCleaning;
